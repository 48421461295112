<!--
Display gtfs route information 
-->

<template>
  <v-card-text>
    <v-form v-model="valid" ref="form">
      <whale-text v-model="local_project_name" :label="$t('project.settings.info.name')" required focus />
      <whale-text
        v-model="local_project_description"
        :label="$t('project.settings.info.description')"
        area
        :max-length="false"
        :required="false"
      />
      <v-container>
        <v-row justify="end">
          <v-spacer></v-spacer>
          <v-col v-if="showCancel" class="mr-4" style="flex-grow: 0" @click="cancel">
            <v-btn>{{ $t("project.cancelSave") }}</v-btn>
          </v-col>
          <v-col class="mr-4" style="flex-grow: 0">
            <v-btn color="primary" :disabled="!valid || async[action] > 0" @click="save">
              {{ $t("project.save") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card-text>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.component("project-descriptor", {
  components: {},
  props: {
    project: {
      required: false
    },
    resetOnSave: {
      type: Boolean
    },
    showCancel: {
      type: Boolean
    },
    action: {
      type: String
    }
  },

  data() {
    return {
      valid: false,
      local_project_name: "",
      local_project_description: ""
    };
  },

  watch: {
    project: {
      handler(value) {
        this.local_project_name = value.name;
        this.local_project_description = value.description;
      },
      immediate: true
    }
  },

  computed: {
    ...mapState(["async"])
  },

  methods: {
    save() {
      this.$emit("save", {
        name: this.local_project_name,
        description: this.local_project_description
      });
      if (this.resetOnSave) {
        this.resetForm();
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    resetForm() {
      this.local_project_name = "";
      this.local_project_description = "";
      this.$refs.form.resetValidation();
    }
  }
});
</script>
