<template>
  <v-card :width="width">
    <v-card-title class="headline grey lighten-2">{{ title }}</v-card-title>
    <v-card-text></v-card-text>
    <slot />
    <v-card-actions><v-divider /></v-card-actions>
    <v-card-actions>
      <v-btn color="primary" v-if="showClear" text @click="clearContent">
        {{ $t("basic_dialogs.clear") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" v-if="showCancel" text @click="closeDialog">
        {{ $t("basic_dialogs.cancel") }}
      </v-btn>
      <v-btn color="primary" :disabled="disableValidation" text @click="validate">
        {{ $t("basic_dialogs.validate") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.component("kite-map-action", {
  props: {
    // map action name
    action: {
      type: String,
      required: true
    },
    // map action title
    title: {
      type: String,
      required: true
    },
    // returns the result of the map action
    getResult: {
      type: Function,
      required: true
    },
    // clears map action component
    clearContent: {
      type: Function
    },
    // indicates if component should be cleared on close
    clearOnClose: {
      type: Boolean,
      default: true
    },
    // show clear button
    showClear: {
      type: Boolean,
      default: true
    },
    // show cancel button
    showCancel: {
      type: Boolean,
      default: true
    },
    // function called when action is opened
    onOpen: {
      type: Function
    },
    // function called when action is closed
    onClose: {
      type: Function
    },
    width: {
      type: Number,
      default: 500
    },
    // disable validation button
    disableValidation: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {};
  },
  watch: {
    mapActionState: {
      handler: function () {
        if (this.onOpen && this.mapActionState.action == this.action) {
          this.onOpen(this.mapActionState);
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState(["mapActionState"])
  },
  methods: {
    ...mapActions(["finishMapAction", "closeMapAction"]),
    closeDialog() {
      // clear action content
      if (this.clearContent && this.clearOnClose) {
        this.clearContent();
      }

      // call onClose handler if provided
      if (this.onClose) {
        this.onClose();
      }

      // close map action dialog
      this.closeMapAction();
    },
    validate() {
      // get map action result
      let result = this.getResult();

      // finish map action
      this.finishMapAction(result);

      // close map action dialog
      this.closeDialog();
    }
  }
});
</script>
