<template>
  <div>
    <kite-add-layer
      :title="$t('map_layers.layer_from_admin.title')"
      :read-input-data="getGeojsonFromInput"
      :allow-union="true"
    >
      <template v-slot:layerImport="{ inputState, newInput }">
        <span v-html="$t('map_layers.layer_from_admin.description')"></span>
        <v-row>
          <v-col md="7"> </v-col>
          <v-col md="2">
            <v-btn text color="primary" @click="selectAdminEntities(inputState, newInput)">
              {{ $t("basic_dialogs.start") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </kite-add-layer>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import KiteAddLayer from "./kite_add_layer.vue";
import { validateFeatureCollection } from "@/validation";
import { mapState, mapActions } from "vuex";

export default Vue.component("layer-from-admin", {
  components: {
    KiteAddLayer
  },

  data: function () {
    return {};
  },

  computed: {},

  methods: {
    ...mapActions(["startMapAction"]),
    async selectAdminEntities(inputState, newInput) {
      // start a drawing

      let handleAdminSelect = payload => {
        inputState.value = payload;
        newInput();
      };

      let success = await this.startMapAction({
        action: "zone_selection",
        validationHandler: handleAdminSelect,
        closeHandler: this.handleClose,
        options: {
          return_geojson: true
        }
      });

      // if the draw dialog opened correctly, close
      if (success) {
        this.$store.commit("layers/UPDATE_ADD_DIALOG", false);
      }
    },
    handleClose() {
      this.$store.commit("layers/UPDATE_ADD_DIALOG", true);
    },
    async getGeojsonFromInput(input, setDataName) {
      let geojson = input?.spatial_selection_geojson;

      let nb_features = geojson.features?.length || 0;

      let name;
      if (nb_features == 0) {
        throw new Error("The geometry has 0 features");
      } else if (nb_features == 1) {
        name = geojson.features[0].properties.NOM;
      } else {
        name = this.$t("map_layers.layer_from_admin.layer_name", {
          nb_entities: nb_features,
          level_name: this.$t("flows.zonings." + input.spatial_selection_zoning)
        });
      }
      setDataName(name);

      return geojson;
    }
  }
});
</script>
