<!--
A dialog with tabs containing NewDataPage components.
Tabs are defined by the 'tabs' prop.
Tabs' icons are hard coded.
Tabs' titles are fetched in i18n add_dialog.tabs
-->

<template>
  <div>
    <kite-dialog v-model="dialogModel">
      <v-tabs
        v-model="tabModel"
        background-color="primary"
        class="elevation-2"
        dark
        grow
        icons-and-text
        :slider-size="SLIDER_SIZE"
      >
        <v-tabs-slider :color="SLIDER_COLOR" />
        <v-tab v-for="(tab, i) in tabs" :key="i">
          {{ $t("add_dialog.tabs." + tab) }}
          <v-icon> {{ TABS_ICONS[tab] }}</v-icon>
        </v-tab>

        <v-tab-item v-for="(tab, i) in tabs" eager :key="i">
          <slot :name="tab"> </slot>
        </v-tab-item>
      </v-tabs>
    </kite-dialog>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" v-bind="buttonOptions" color="primary" rounded large left dark @click="dialogModel = true">
          <v-icon left>mdi-plus</v-icon>{{ buttonText }}
        </v-btn>
      </template>
      <span>{{ tooltipText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from "vue";
import { SLIDER_SIZE, SLIDER_COLOR } from "@/global";

const TABS_ICONS = {
  database: "storage",
  file: "upload_file",
  url: "link",
  draw: "draw",
  admin: "map",
  project: "folder_shared"
};

export default Vue.component("kite-add-dialog", {
  props: {
    dialog: {
      type: Boolean,
      required: true
    },
    updateDialog: {
      type: Function,
      required: true
    },
    tabs: {
      type: Array,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonOptions: {
      type: Object
    },
    tooltipText: {
      type: String,
      required: false,
      default: "tooltip"
    }
  },

  data: function () {
    return {
      tab: 0,
      TABS_ICONS,
      SLIDER_SIZE,
      SLIDER_COLOR
    };
  },

  computed: {
    dialogModel: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.updateDialog(value);
      }
    },
    tabModel: {
      get() {
        return this.tab;
      },
      set(value) {
        this.tab = value;
        this.$emit("update:tab", value);
      }
    }
  },

  methods: {}
});
</script>
