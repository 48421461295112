<!--
  Kite main app bar
-->

<template>
  <component :is="component" v-model="model" v-bind="$attrs" :counter="counter" :required="required" :rules="rules" />
</template>

<script lang="ts">
import Vue from "vue";
import { name_max_size, nameRegexRule, nameLengthRule, requiredRule } from "@/validation";
import { VTextField, VTextarea } from "vuetify/lib";

export default Vue.component("whale-text", {
  components: {
    VTextField,
    VTextarea
  },
  props: {
    // text value
    value: {
      type: String
    },
    // wether the value is required or not
    required: {
      type: Boolean,
      default: false
    },
    // maximum length of the text
    maxLength: {
      type: [Number, Boolean],
      default: true
    },
    // create a text-area component instead of a text-field
    area: {
      type: Boolean
    },
    // additional rules applied to the text value
    additionalRules: {
      type: Array
    }
  },
  data: () => ({
    name_max_size
  }),
  computed: {
    component() {
      if (this.area) {
        return "v-textarea";
      } else {
        return "v-text-field";
      }
    },
    rules() {
      // main regex rules
      let rules = [nameRegexRule];

      // required value rule
      if (this.required) {
        rules.push(requiredRule);
      }

      // length rule
      if (this.maxLength) {
        if (this.maxLength === true) {
          rules.push(nameLengthRule);
        } else if (typeof this.maxLength == "number") {
          rules.push(value => {
            return (
              value.length <= this.maxLength ||
              this.$t("add_dialog.errors.name_length_error", { max_size: this.maxLength })
            );
          });
        } else {
          throw new Error("max-length value must be a number or a Boolean");
        }
      }

      // user provided rules
      if (this.additionalRules) {
        rules = [...rules, ...this.additionalRules];
      }

      return rules;
    },
    counter() {
      if (this.maxLength) {
        if (this.maxLength === true) {
          return name_max_size;
        } else if (typeof this.maxLength == "number") {
          return this.maxLength;
        } else {
          throw new Error("max-length value must be a number or a Boolean");
        }
      } else {
        return undefined;
      }
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {}
});
</script>
