<template>
  <kite-add-data
    :title="title"
    :read-input-data="readInputData"
    :validate-data="validateFeatureCollection"
    :new-data-name="newName"
    :evaluate-data-info="evaluateDataInfo"
    :new-config="newConfig"
    :config-validator="isConfigValid"
    :add-data="addDataToKite"
    :close="closeDialog"
  >
    <template v-slot:import="scope">
      <slot name="layerImport" v-bind="scope" />
    </template>
    <template v-slot:stats="{ dataInfo }">
      <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("add_dialog.titles.stats") }}</h3>
      <p>{{ $t("map_layers.add_page.stats.nb_features") + dataInfo.features }}</p>
      <p>{{ $t("map_layers.add_page.stats.geometry") + $t("map_layers.geometries." + dataInfo.geometry) }}</p>
    </template>
    <template v-slot:config="{ addConfig, dataInfo }">
      <h3 :style="{ color: $vuetify.theme.themes.light.primary }">{{ $t("add_dialog.titles.config") }}</h3>
      <v-select
        v-model="addConfig.type"
        prepend-icon="shape_line"
        :label="$t('map_layers.add_page.type')"
        :items="addConfig.available_types"
        :no-data-text="$t('add_dialog.basics.no_data')"
      >
      </v-select>
      <v-checkbox
        v-if="allowUnion && addConfig.type != 'KiteMaskLayer' && dataInfo.features > 1"
        v-model="addConfig.union"
        :label="'Fusionner les entités en un polygone unique'"
        required
        on-icon="radio_button_checked"
        off-icon="radio_button_unchecked"
        :ripple="false"
      ></v-checkbox>
    </template>
  </kite-add-data>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import { addCustomLayer, LAYER_CLASSES } from "@/kite_layers";
import { mandatoryRule, validateFeatureCollection } from "@/validation";
import { unionOfGeojsonFeatures } from "@/functions-tools";
import i18n from "@/plugins/lang";
import KiteAddData from "@/components/kite/base/kite_add_data.vue";

export default Vue.component("kite-add-layer", {
  props: ["title", "readInputData", "addLayerFunction", "allowUnion"],
  components: {
    KiteAddData
  },

  data: function () {
    return {
      layer_types: {
        multiple: ["Multiple"],
        Point: ["KiteCircleLayer"],
        LineString: ["KiteLineLayer", "OffsetLineLayer"],
        Polygon: ["KiteLineLayer", "KiteFillLayer", "KiteMaskLayer"]
      }
    };
  },
  computed: {
    ...mapState("layers", ["nbCustomLayers"])
  },

  methods: {
    validateFeatureCollection,

    newName() {
      return this.$t("map_layers.add_page.name_prefix") + this.nbCustomLayers.toString();
    },

    evaluateDataInfo(data) {
      if (data) {
        return {
          features: data.features.length.toString(),
          geometry: this.get_geojson_geometry(data),
          properties: this.get_geojson_properties(data)
        };
      } else {
        return {
          features: "",
          geometry: "no_data",
          properties: []
        };
      }
    },

    get_geojson_geometry(geojson) {
      let geometries = geojson.features.map(feature => {
        if (feature.geometry) {
          let geometry = feature.geometry.type;
          if (geometry.startsWith("Multi")) {
            geometry = geometry.substring(5);
          }
          return geometry;
        }
      });
      geometries = [...new Set(geometries)];
      if (geometries.length > 1) {
        Object;
        return "multiple";
      } else if (geometries.length == 0) {
        return "empty";
      } else {
        return geometries[0];
      }
    },
    get_geojson_properties(geojson) {
      return Object.keys(geojson.features[0].properties);
    },

    getDataTypes(data, data_info) {
      let layer_types = this.layer_types[data_info.geometry];
      if (layer_types == undefined) {
        return layer_types;
      }
      layer_types = layer_types.map(item => {
        if (item == "Multiple") {
          return { value: "Multiple", text: "Multiple" };
        } else {
          let layer_class = LAYER_CLASSES[item];
          return { value: item, text: this.$t("kite_layers.types." + layer_class.itemType()) };
        }
      });
      return layer_types;
    },

    newConfig(data, data_info) {
      if (data) {
        let available_types = this.getDataTypes(data, data_info);
        let first_type = available_types[0];
        if (typeof first_type == "object") {
          first_type = first_type.value;
        }
        return {
          available_types,
          type: first_type,
          additional_properties: {},
          union: this.allowUnion
        };
      } else {
        return {
          available_types: [],
          type: null,
          additional_properties: {},
          union: this.allowUnion
        };
      }
    },

    isConfigValid(config) {
      return !!config.type;
    },

    addDataToKite(data, name, config) {
      if (this.addLayerFunction == undefined) {
        this.defaultAddCustomLayer(data, name, config);
      } else {
        this.addLayerFunction(data, name, config);
      }
    },

    defaultAddCustomLayer(data, name, config) {
      // manage case of layer type "Multiple"
      let layer_class_name = config.type;
      if (layer_class_name == "Multiple") {
        layer_class_name = "KiteGeojsonLayer";
      }

      // merge features if config.union
      if (config.union && config.type != "KiteMaskLayer" && data.features.length > 1) {
        data = unionOfGeojsonFeatures(data);
      }

      // add a new custom layer from the add config and data
      addCustomLayer({
        layer_class_name,
        data,
        name,
        additionalProperties: config.additional_properties
      });
    },

    closeDialog() {
      this.$store.state.layers.addDialog = false;
    }
  }
});
</script>
