<template>
  <kite-add-dialog
    :dialog="addDialog"
    :tabs="['database', 'file', 'url', 'draw', 'admin', 'project']"
    :update-dialog="updateAddDialog"
    :button-text="$t('map_layers.layer_add')"
    @update:tab="updateAddTab"
    :tooltip-text="$t('map_layers.tooltips.layer_add')"
  >
    <template #database>
      <layer-database />
    </template>
    <template #file>
      <layer-from-file />
    </template>
    <template #url>
      <layer-from-url />
    </template>
    <template #draw>
      <layer-from-draw />
    </template>
    <template #admin>
      <layer-from-admin />
    </template>
    <template #project>
      <layer-from-project />
    </template>
  </kite-add-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import KiteAddDialog from "@/components/kite/base/kite_add_dialog.vue";
import LayerFromFile from "./layer_from_file.vue";
import LayerFromUrl from "./layer_from_url.vue";
import LayerFromDraw from "./layer_from_draw.vue";
import LayerFromAdmin from "./layer_from_admin.vue";
import LayerFromProject from "./layer_from_project.vue";
import LayerDatabase from "./layer_database.vue";
import { mapState, mapActions } from "vuex";

export default Vue.component("add-layer-menu", {
  components: {
    KiteAddDialog,
    LayerFromFile,
    LayerFromUrl,
    LayerFromDraw,
    LayerFromAdmin,
    LayerFromProject,
    LayerDatabase
  },

  data: function () {
    return {};
  },

  computed: {
    ...mapState("layers", ["addDialog"])
  },

  methods: {
    ...mapActions("layers", ["updateAddDialog", "updateAddTab"])
  }
});
</script>
