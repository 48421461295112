<template>
  <kite-map-action
    action="zone_selection"
    :title="$t('map_actions.zone_selection.title')"
    :get-result="getSelection"
    :clear-content="deleteSelection"
    :clear-on-close="false"
    :show-cancel="false"
    :on-open="onOpen"
    :on-close="onClose"
  >
    <v-card-text><span v-html="$t('map_actions.zone_selection.desc')"></span></v-card-text>
    <v-card-text class="px-6 pt-6 pb-0">
      <zoning-selector
        v-model="spatial_selection_zoning"
        @input="updateSelectedZoning"
        solo
        return-object
        :loading="loading"
      />
    </v-card-text>
  </kite-map-action>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import KiteMapAction from "./kite_map_action.vue";
import { KiteFillSelectLayer } from "@/kite_layers";
import { getMartinSource } from "@/functions-tools";
import { ZONING_YEAR, IRIS_ZONING_YEAR } from "@/global";
import ZoningSelector from "@/components/kite/base/zoning_selector.vue";

const select_layer_id = "flow_selection";

export default Vue.component("kite-zone-selection", {
  components: {
    KiteMapAction,
    ZoningSelector
  },
  data: function () {
    return {
      spatial_selection_zoning: { id: "epci_2024", zoning: "epci" },
      spatial_selection_ids: [],
      spatial_selection_names: [],
      spatial_selection_layer: null
    };
  },
  computed: {
    ...mapState(["mapActionState"]),
    ...mapState("flows", ["zoningSummaryTable"]),
    ...mapState("layers", ["map", "allLayers", "zList"]),
    loading() {
      if (!this.spatial_selection_layer) {
        return false;
      } else {
        return this.spatial_selection_layer.source.loading;
      }
    }
  },
  methods: {
    ...mapActions("layers", ["setLayersVisibility", "deleteLayer"]),
    onOpen(_map_action_state) {
      // init selected zoning
      if (!this.spatial_selection_zoning) {
        this.spatial_selection_zoning = this.zoningSummaryTable[0];
      }

      // make selection layer visible
      if (this.spatial_selection_layer == null) {
        if (select_layer_id in this.allLayers) {
          this.spatial_selection_layer = this.allLayers[select_layer_id];
        } else {
          this.createSelectionLayer();
        }
      }
      this.setLayersVisibility({ ids: select_layer_id, isVisible: true });
    },
    onClose() {
      this.setLayersVisibility({ ids: select_layer_id, isVisible: false });
    },
    updateSelectedZoning() {
      this.deleteSelection();
      this.createSelectionLayer();
    },
    async getSelection() {
      let spatial_selection_geojson = undefined;
      if (this.mapActionState.options?.return_geojson) {
        let raw_geojson = await this.spatial_selection_layer.source.getSpatialData();
        let selected_features = raw_geojson.features.filter(feature => {
          return this.spatial_selection_ids.includes(feature.properties.id);
        });
        spatial_selection_geojson = {
          type: "FeatureCollection",
          features: selected_features
        };
      }

      return {
        spatial_selection_zoning: this.spatial_selection_zoning.zoning,
        spatial_selection_ids: this.spatial_selection_ids,
        spatial_selection_names: this.spatial_selection_names,
        spatial_selection_geojson
      };
    },
    deleteSelection() {
      this.spatial_selection_ids = [];
      this.spatial_selection_names = [];
      if (this.spatial_selection_layer != null) {
        this.spatial_selection_layer.clearState();
      }
    },
    // create the spatial selection layer
    async createSelectionLayer() {
      // rebuild a new layer at each zoning change
      if (select_layer_id in this.allLayers) {
        this.$store.state.overlayZoningSelection = true; // init overlay for vector flow_selection
        this.deleteLayer(this.spatial_selection_layer);
      }

      let layer_data = this.selection_layer_data();
      let props = {
        layer_class: KiteFillSelectLayer,
        startsVisible: true,
        id: select_layer_id,
        data: layer_data,
        sourceProps: {
          promoteId: "id",
          minzoom: 4
        },
        sourceType: "vector",
        editable: true,
        editAttributes: {
          color: "#ff0000",
          opacity: 0.5
        },
        name: "Flow spatial selection",
        beforeId: this.zList[0],
        display_mode: "hidden"
      };
      this.spatial_selection_layer = new KiteFillSelectLayer(props, this.map);
      this.spatial_selection_layer.addLayerToKite();

      let vm = this;

      // add the zonings id and name when they are selected
      this.spatial_selection_layer.addClickAction(
        // add action on feature selection
        feature => {
          let id = feature.properties.id;
          vm.spatial_selection_ids.push(id);
          let name = feature.properties.name;
          vm.spatial_selection_names.push(name);
        },
        // add action on feature unselection
        feature => {
          let id = feature.properties.id;
          vm.spatial_selection_ids = vm.spatial_selection_ids.filter(el => el != id);
          let name = feature.properties.name;
          vm.spatial_selection_names = vm.spatial_selection_names.filter(el => el != name);
        }
      );

      // add a popup when hovering the polygons
      this.spatial_selection_layer.addPopup(e => {
        var name = e.features[0].properties.name;
        let text = "<b>" + name + "</b>";
        return text;
      });
    },

    // name of the source file depending on the selection zoning
    selection_layer_data() {
      const year = this.spatial_selection_zoning.zoning == "iris" ? IRIS_ZONING_YEAR : ZONING_YEAR;
      return getMartinSource(this.spatial_selection_zoning.zoning + "_" + year);
    }
  }
});
</script>
