<!--
A "select-all" choice to prepend for select components
-->

<template>
  <div>
    <v-list-item @click="selectAll">
      <v-list-item-action>
        <v-icon :color="selectedLength > 0 ? 'primary' : ''">
          {{ icon }}
        </v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title> {{ selectAllText }} </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider class="mt-2"></v-divider>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import { coordinatesToText } from "@/functions-tools";

export default Vue.component("kite-select-all", {
  props: {
    // "items" prop of the v-select
    items: {
      required: true,
      type: Array
    },
    // number of selected items, ie model.length
    selectedLength: {
      required: true,
      type: Number
    },
    // optional text to replace "Select all"
    text: {
      type: String
    }
  },
  data: () => ({}),

  computed: {
    allSelected() {
      return this.selectedLength == this.items.length;
    },
    selectAllText() {
      return this.text || this.$t("basic_dialogs.select_all");
    },
    icon() {
      if (this.allSelected) return "mdi-close-box";
      if (this.selectedLength > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    selectAll() {
      if (this.allSelected) {
        this.$emit("update:selection", []);
      } else {
        this.$emit("update:selection", this.items.slice());
      }
    }
  }
});
</script>
