import { MapboxLayer } from "@deck.gl/mapbox";
import { PathLayer, ScatterplotLayer, ArcLayer } from "@deck.gl/layers";
import FlowMapLayer from "@flowmap.gl/core";
import mapboxgl from "mapbox-gl";
import { hexToRGB, hexaToHex, majVisibility, rgbToHex, maskFromPolygon } from "./functions-tools";
import { add_icons_to_map } from "@/components/kite/trace/trace";
import { roundFlows } from "@/flows";
import PitchToggle from "./toggle3D";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { personalDataSourceName } from "@/models";
import { DECK_PT_COLORS, COLORS, BASE_LAYERS, DATA_LAYERS_PROP_LABELS } from "./global";
import store from "./store";
import i18n from "./plugins/lang";
import { getMartinSource, getSharkSource, mergeClippedFeatures, bbox_from_map } from "@/functions-tools";
import { getInstance } from "@/api/index";
import { jsonToPropsMapping, PropsMapping, TooltipMapping, isPropsMappingInit } from "./props_mapping";
import * as turf from "@turf/turf";

/**
 * Global layer setup for Kite
 * @param map loaded Mapbox Map
 */
async function setup_kite_layers(map) {
  if (store.state.layers.zList.length > 0) {
    store.commit("layers/RESET_STORE");
  }

  // set map in stores
  store.commit("layers/SET_MAP", map);
  store.commit("SET_MAP_INITIALIZED", true);
  let whale = getInstance();
  whale.setMap(map);

  // create kite builtin layers objects (we may do this earlier, no need for map to be loaded)
  create_kite_predefined_layers();

  // add icons to mapbox
  await add_icons_to_map(map);

  // add background layers
  add_background_layers(map);
  // set default background layer
  store.commit("layers/CHANGE_BASE_LAYER", store.state.layers.baseLayer);

  // add builtin layers
  add_predefined_layers_to_map();

  // create unified popup
  createUnifiedPopup();

  // add listeners for loading layers
  // CAUTION : we suppose that layers have the same id than their source !
  // if not, we would need to store the sources in the store (like allLayers)

  map.on("sourcedataloading", e => {
    if (e.dataType == "source" && e.sourceDataType == undefined && !e.isSourceLoaded) {
      store.dispatch("layers/setLayersLoading", { ids: e.sourceId, value: true });
    }
  });
  map.on("sourcedata", e => {
    if (e.dataType == "source" && e.sourceDataType == undefined && e.isSourceLoaded) {
      store.dispatch("layers/setLayersLoading", { ids: e.sourceId, value: false });
    }
  });
  map.on("idle", () => {
    store.dispatch("layers/setLayersLoading", { ids: "all", value: false });
  });
  map.on("error", e => {
    if (e.sourceId && store.getters["layers/getLayer"](e.sourceId)) {
      store.getters["layers/getLayer"](e.sourceId).signalLayerError(e);
    } else {
      console.log(e);
      let message = i18n.t("errors.mapbox.generic", { message: e.error.message });
      alert({ message, type: "error" });
    }
  });
}

// add the background layers
function add_background_layers(map) {
  for (let info of BASE_LAYERS) {
    let name = info.name;
    let layer = map.getLayer(name);
    if (typeof layer === "undefined") {
      map.addSource(name, {
        type: "raster",
        tiles: [info.tiles],
        tileSize: info.tileSize || 256,
        attribution: info.attribution
      });
      map.addLayer({
        type: "raster",
        id: name,
        source: name,
        layout: {
          visibility: "none"
        }
      });
    }
  }
}

/**
 * Change the background layer (raster) of the map.
 * The function add_background_layers must have been called on map previously.
 * @param map
 * @param base_layer
 */
function change_base_layer(map, base_layer) {
  // if base_layer is not in BASE_LAYERS, change to default baseLayer
  let selected = "osm-classic";
  // hide other layers
  for (let i = 0; i < BASE_LAYERS.length; i++) {
    let p = BASE_LAYERS[i];
    if (p.name === base_layer) {
      selected = p.name;
    } else {
      map.setLayoutProperty(p.name, "visibility", "none");
    }
  }
  // show selected base layer
  map.setLayoutProperty(selected, "visibility", "visible");
}

// add Kite's predefined layers, stored in KITE_LAYERS
function create_kite_predefined_layers() {
  KITE_LAYERS.forEach(layer_info => {
    if (!(layer_info.id in store.state.layers.allLayers)) {
      // missing beforeId, parentId, name
      // @ts-ignore
      create_kite_layer(layer_info);
    }
  });
}

function add_predefined_layers_to_map() {
  let orderedLayers = KITE_LAYERS.map(el => el.id);
  let allLayers = store.state.layers.allLayers;

  orderedLayers.forEach(id => {
    let layer = allLayers[id];
    layer.addLayerToKite();
  });
}

// create and add kite layer from db data given in parameter
function create_kite_layer_from_db(data, visible: boolean = true) {
  let map = store.state.layers.map;
  let layer_info = JSON.parse(JSON.stringify(data));
  layer_info.startsVisible = visible;
  layer_info.layer_class = LAYER_CLASSES[layer_info.layer_class];

  // additional treatment for some source types
  let sourceType = layer_info.sourceType;
  if (sourceType === null) {
    layer_info.sourceType = undefined;
  } else if (sourceType == "vector") {
    layer_info.data = getMartinSource(layer_info.data);
  } else if (sourceType == "shark") {
    layer_info.data = getSharkSource(layer_info.data, bbox_from_map(map));
    layer_info.sourceType = "geojson";
  }

  // speard additionalProperties dict
  layer_info = {
    ...layer_info,
    ...(layer_info.additionalProperties || {})
  };

  layer_info.category = "misc";
  if (!("editable" in layer_info)) {
    layer_info.editable = true;
  }

  // adapt format #deprecatedEditAttributes
  if (layer_info.editConstraints?.tooltip) {
    layer_info.dataProperties = layer_info.editConstraints.tooltip;
  }
  delete layer_info["editConstraints"];
  if (isPropsMappingInit(layer_info.editAttributes?.color)) {
    layer_info.editAttributes.color.legend = true;
  }
  layer_info.editAttributes = repair_edit_attributes(layer_info.layer_class, layer_info.editAttributes);

  layer_info.sourceProps = layer_info.sourceProps || {};
  layer_info.sourceProps.promoteId = layer_info.promoteId || layer_info.sourceProps.promoteId;
  if (!layer_info.sourceProps.promoteId || layer_info.sourceProps.promoteId === "") {
    layer_info.sourceProps.generateId = true;
    delete layer_info.sourceProps["promoteId"];
  }
  layer_info.saved = true;
  layer_info.display_mode = "user";

  // create and add the layer
  let layer_id = create_kite_layer(layer_info, true);

  // check if layer data is empty for viewport_limiter layers
  let layer = store.getters["layers/getLayer"](layer_id);
  if (layer_info.viewport_limited) {
    layer.source.getSpatialData().then(geojson => {
      if (geojson.features.length == 0) {
        alert({ message: i18n.t("map_layers.database_layers.empty_viewport_limited_layer"), type: "warning" });
      }
    });
  }
}

function addCustomLayer({
  layer_class_name,
  data,
  name,
  editAttributes = undefined,
  startsVisible = true,
  beforeId = undefined,
  saved = undefined,
  additionalProperties = undefined
}) {
  // build layer constructor from given information
  const id = "customlayer:" + store.state.layers.nbCustomLayers;
  let layer_constructor = {
    layer_class: LAYER_CLASSES[layer_class_name],
    id,
    name,
    category: "custom",
    label: "",
    startsVisible,
    data,
    editable: true,
    editAttributes,
    beforeId,
    parentId: undefined,
    saved,
    display_mode: "user",
    ...additionalProperties
  };

  // create and add the layer
  let layer_id = create_kite_layer(layer_constructor, true);

  // update number of custom layers
  store.commit("layers/INCREMENT_NB_CUSTOM_LAYERS");

  return layer_id;
}

function create_kite_layer(layer_info, add_to_map = false) {
  try {
    // create the layer object
    let layer = new layer_info.layer_class(layer_info);

    // add the layer to Kite's layer collection
    store.dispatch("layers/addLayerToCollection", layer);

    // if asked, add the layer to the map and insert in the zList
    if (add_to_map) {
      layer.addLayerToKite();
    }
    return layer.id;
  } catch (e) {
    let id = layer_info.id || "<no id provided>";
    console.log("An error occured while creating the layer " + id + ": " + e);
    throw e;
  }
}

function createUnifiedPopup() {
  // create and store unified popup
  var popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false });
  popup.trackPointer();
  store.commit("layers/SET_UNIFIED_POPUP", popup);

  // add map event
  let map = store.state.layers.map;
  map.on("mousemove", e => {
    let layers = store.getters["layers/orderedLayersPopup"];
    if (layers.length == 0) {
      popup.remove();
      return;
    }

    let output_text = ""; // global popup html
    let layer_text; // layer html (title + features)
    let feature_text; // feature html (prop name + value)
    let layer;
    let features;
    // browse popup layers ordered by zList and write features
    for (let layer_id of store.getters["layers/orderedLayersPopup"].reverse()) {
      layer_text = "";
      // get layer object
      layer = store.getters["layers/getLayer"](layer_id);
      // get list of displayed features of the layer in the click area
      features = layer.getFeatures(e, 5);
      // for each feature, get html to display in popup
      for (let feature of features) {
        feature_text = layer.tooltipTextFromFeature(feature);
        if (feature_text.length > 0) {
          layer_text += feature_text + "<hr/>";
        }
      }

      // if display is non empty, add layer title and features' html
      if (layer_text.length > 0) {
        if (output_text.length > 0) {
          output_text += "<br/>";
        }
        output_text +=
          "<h3 style='text-align: center;background-color: " +
          COLORS["secondary"] +
          ";padding: 3px;color: black;border: 2px solid " +
          COLORS["secondary"] +
          ";border-radius: 10px;'>" +
          layer.getName() +
          "</h3>" +
          layer_text.substring(0, layer_text.length - 5);
        +"<br/>";
      }
    }

    // if popup is non empty, trigger display
    if (output_text.length > 0) {
      popup.setHTML(output_text).addTo(map);
    } else {
      popup.remove();
    }
  });
}

/**
 * Class containing information about a mapbox layer source
 */
abstract class KiteSource {
  map: any;
  id: String;
  data: any;
  sourceProps: any;
  sourceLayer: any;
  loading: boolean;
  dynamic: boolean = false;
  constructor(id, data, sourceProps, dynamic = false) {
    this.map = null;
    this.id = id;
    this.data = data;
    this.sourceProps = sourceProps;
    this.sourceLayer = undefined;
    this.loading = data == null ? false : true;
    this.dynamic = dynamic;
  }

  setMap(map) {
    this.map = map;
  }

  addSource() {
    let source = this.map.getSource(this.id);
    if (source === undefined) {
      this.map.addSource(this.id, this.getSourceObj());
    }
  }

  getSourceObj() {}

  abstract setData(data: any);

  abstract updateLayerProps(layerProps: any);

  abstract getType();

  abstract getFeatureProps();

  abstract getData();

  /**
   * Get a GeoJSON containing all features of the current viewport (features are truncated at viewport limit)
   * Requires that the source features have an ID (from promoteId or generateId)
   * @returns
   */
  async getSpatialData() {
    let map = this.map;

    // get layer's rendered features on all viewport
    let rendered_features = map.queryRenderedFeatures(undefined, { layers: [this.id] });

    // special processing for Polygon-like features
    if (
      rendered_features.length > 0 &&
      (rendered_features[0].geometry.type == "Polygon" || rendered_features[0].geometry.type == "MultiPolygon")
    ) {
      // get source features and selected the ones that are rendered
      let source_features = map.querySourceFeatures(this.id, { sourceLayer: this.sourceLayer });
      let rendered_features_ids = rendered_features.map(feature => feature.id);
      rendered_features = source_features.filter(feature => rendered_features_ids.includes(feature.id));

      // Polygon-like features from vector tiles can be clipped, so we merge them
      rendered_features = mergeClippedFeatures(rendered_features);

      // clip polygons in viewport, so that it is clear that features are cut
      let bbox = bbox_from_map(map);
      rendered_features = rendered_features.map(f => {
        return turf.bboxClip(f, <[number, number, number, number, number, number]>bbox);
      });
    }

    return {
      type: "FeatureCollection",
      features: rendered_features
    };
  }

  abstract setLoading(value: boolean);
}

/**
 * Class representing a "geojson" source
 */
class GeojsonSource extends KiteSource {
  getType() {
    return "geojson";
  }

  getSourceObj() {
    return {
      ...this.sourceProps,
      type: this.getType(),
      data: this.data
    };
  }

  setData(data: any) {
    this.data = data;
    if (!this.dynamic && data) {
      this.loading = true;
    }
    // data is set via the source for the mapbox layers
    this.map.getSource(this.id).setData(data);
  }

  updateLayerProps(layerProps: any) {
    if (!("source" in layerProps)) {
      layerProps.source = this.id;
    }
  }

  getFeatureProps() {
    let properties = [];
    if (this.data) {
      let features = [];
      if (typeof this.data == "string" && this.data.startsWith("http")) {
        features = this.map.querySourceFeatures(this.id);
      } else if (typeof this.data == "object") {
        features = this.data.features;
      }
      if (features.length > 0) {
        let feature = features[0];
        properties = Object.keys(feature.properties || {});
      }
    }
    return properties;
  }

  getData() {
    return this.data;
  }

  async getSpatialData() {
    let data_type = typeof this.data;
    switch (data_type) {
      case "object":
        // directly return the stored data
        return this.data;
      case "string":
        // fetch features by querying source features
        return KiteSource.prototype.getSpatialData.call(this);
      default:
        throw new Error("getSpatialData is unsupported for data with type " + data_type);
    }
  }

  setLoading(value: boolean) {
    // loading is set to true by the setData method
    if (!value) {
      this.loading = value;
    }
  }
}

class GeojsonMaskSource extends GeojsonSource {
  mask_data: any;

  constructor(id, data, sourceProps, dynamic = false) {
    super(id, data, sourceProps, dynamic);
    this.mask_data = maskFromPolygon(data);
  }

  getSourceObj() {
    return {
      ...this.sourceProps,
      type: this.getType(),
      data: this.mask_data
    };
  }

  setData(data: any) {
    this.data = data;
    this.mask_data = maskFromPolygon(data);
    if (!this.dynamic && data) {
      this.loading = true;
    }
    // data is set via the source for the mapbox layers
    this.map.getSource(this.id).setData(this.mask_data);
  }

  getFeatureProps(): any[] {
    return [];
  }
}

/**
 * Class representing a "vector" source
 */
class VectorTilesSource extends KiteSource {
  getType() {
    return "vector";
  }

  getSourceObj() {
    return {
      ...this.sourceProps,
      type: this.getType(),
      url: this.data.url
    };
  }

  updateLayerProps(layerProps: any) {
    if (!("source" in layerProps)) {
      layerProps.source = this.id;
    }
    if ("source-layer" in this.data) {
      this.sourceLayer = this.data["source-layer"];
      layerProps["source-layer"] = this.sourceLayer;
    }
  }

  async setData(data: any) {
    throw new Error("Cannot set data of vector tiles layer. Create a new layer instead");
  }

  getFeatureProps() {
    let properties = [];
    let features = this.map.querySourceFeatures(this.id, { sourceLayer: this.sourceLayer });
    if (features.length > 0) {
      let feature = features[0];
      properties = Object.keys(feature.properties);
    }
    return properties;
  }

  getData() {
    throw new Error("Cannot get data of vector tiles layer");
  }

  setLoading(value: boolean) {
    this.loading = value;
  }
}

// Base layer class
export abstract class KiteLayer {
  class_name: string = "KiteLayer";

  editAttributes: any;
  editStore: any = {};
  map: any;
  id: string;
  name: any;
  dataSource: string;
  parentId: any;
  category: any;
  isVisible: boolean;
  beforeId: any;
  editable: boolean;
  enableAttributeTypeChange: boolean = true;
  // TooltipMapping describing the contents of the layer tooltip
  tooltipAttribute: TooltipMapping;
  // store the value used for properties initialisation
  _dataProperties: any;
  // format of data properties is { prop: label } or { prop: { locale_a: label_a, locale_b: label_b, ... } }
  dataProperties: { [prop: string]: string | { [locale: string]: string } };
  // enable tooltip display
  enableTooltip: boolean;
  legend: any;
  layerObj: any;
  layerProps: any;
  sourceProps: any;
  sourceType: any;
  data: any;
  source: any;
  listeners: any;
  saved: string;
  display_mode: string;
  dynamic: boolean = false;
  legend_export: boolean = true;
  filter: any;
  errorState: any;
  /**
   * The layer constructor param contains
   * id: unique layer identifier
   * data: layer data object
   * sourceType: either "geojson", "vector" or null
   * sourceProps: additional source properties
   * layerProps: layer properties
   * editable: boolean indicating if the layer can be edited
   * editAttributes: object containing editable attributes as PropsMapping objects
   * dataProperties: object describing the properties (fields) of the data contained in the layer
   * enableTooltip: boolean indicating if tooltip display is
   * startsVisible: boolean indicating if the layer is initially visible
   * category: one of the categories of Kite's layers (see CATEGORY_COLORS)
   * beforeId: layer before which this layer is added
   * parentId: id of parent layer
   * name: optionnal name that will replace the name fetched in i18n using the id
   * saved: binary hash if flow is saved in the project
   * class_name: name of the layer class
   * display_mode: 'user' (layer added by user), or 'builtin' (kite layer) or 'hidden' (hidden kite layer)
   * dynamic: indicates if layer data is updated dynamically (at regular intervals)
   * legend_export: indicates if a legend corresponding to this layer should be added at map export
   * filters: Mapbox filter applied to the layer
   * errorState: store Mapbox error related to this layer
   * @param {*} layer_constructor
   */
  constructor({
    id,
    data = null,
    sourceType = "geojson",
    sourceProps = {},
    layerProps = {},
    editable = false,
    editAttributes,
    dataProperties,
    enableTooltip = true,
    startsVisible = false,
    category,
    beforeId,
    parentId = undefined,
    name,
    dataSource = personalDataSourceName(),
    saved = undefined,
    display_mode = "builtin",
    dynamic = false,
    legend_export = true,
    filter = null
  }) {
    console.log(sourceType);
    this.map = null;
    if (typeof id != "string") {
      throw new Error("Bad layer identifier provided.");
    }
    this.id = id;
    this.name = name;
    this.dataSource = dataSource;
    this.parentId = parentId;
    this.category = category;
    this.isVisible = startsVisible;
    this.beforeId = beforeId;
    this.editable = editable;
    this._dataProperties = dataProperties;
    this.editAttributes = editAttributes;
    this.enableTooltip = enableTooltip;
    this.layerObj = null;
    this.layerProps = layerProps;
    this.sourceProps = sourceProps;
    this.sourceType = sourceType;
    this.data = data;
    this.dynamic = dynamic;
    this.listeners = {};
    this.filter = filter;
    this.saved = saved;
    this.display_mode = display_mode;
    this.legend_export = legend_export;
  }

  // Initialisation methods

  /**
   * Add the layer to the main layer store and
   * link it to the main map.
   */
  addLayerToKite() {
    // add the layer to the layer collection if not already in
    this.addToCollection();

    // render the layer on the map and add it to Kite's z-list
    store.dispatch("layers/addLayerToKite", this);
  }

  /**
   * Add the layer to the given map.
   * @param map Mapbox map
   */
  addToMap(map) {
    // set the map attribute
    this.setMap(map);

    // create the layer object
    this.layerObj = this.createLayer();

    // link the layer object to the map
    this.addLayer();

    // add listeners on map events
    this.addListeners();

    // add filter
    if (this.filter) {
      this.setFilter(this.filter);
    }
  }

  createSource() {
    let sourceId = this.id;
    if ("source" in this.layerProps) {
      sourceId = this.layerProps["source"];
    }
    console.log(this.sourceType);
    let sourceConstructor = SOURCE_TYPES[this.sourceType];
    return new sourceConstructor(sourceId, this.data, this.sourceProps, this.dynamic);
  }

  /**
   * Create and return a layer object that can be added to Mapbox.
   *
   * @returns the layer object
   */
  createLayer() {
    this.readEditAttributes(this.editAttributes);

    this.source = this.createSource();
    if (this.source) {
      this.source.setMap(this.map);
    }

    let definiteLayerProps: any = {
      ...this.defaultLayerProps(),
      ...this.layerProps
    };

    // set the layer id
    definiteLayerProps.id = this.id;
    // link the layer data
    // @ts-ignore
    this.addData(definiteLayerProps);

    // set the layer type
    definiteLayerProps.type = this.typeProp();

    // setup properties list
    this.readDataProperties();

    return definiteLayerProps;
  }

  /**
   * Add the layer to Kite collection
   */
  addToCollection() {
    if (!(this.id in store.state.layers.allLayers)) {
      store.dispatch("layers/addLayerToCollection", this);
    }
  }

  /**
   *
   * @returns an object containing the default layer props for this class
   */
  defaultLayerProps() {
    return {};
  }

  /**
   * Link the layer properties to the data
   */
  addData(layerProps: any) {
    this.source.updateLayerProps(layerProps);
  }

  /**
   * Return an object containing the type property of the layer.
   */
  abstract typeProp();

  /**
   * Add a new listener to the map (scoped to this layer or not)
   * @param event listened event
   * @param func listener callback function
   * @param name listener name
   * @param on_map indicate if the listener should be listening to the whole map
   */
  newListener(event: string, func, name: string = "default", on_map = false) {
    //console.log("Adding '" + name + "' listener on '" + event + "' event for layer " + this.id);

    if (on_map) {
      this.map.on(event, func);
    } else {
      this.map.on(event, this.id, func);
    }

    if (!(name in this.listeners)) {
      this.listeners[name] = [];
    }

    this.listeners[name].push({
      event,
      function: func,
      on_map
    });
  }

  /**
   * Add map listeners at the layer creation
   */
  addListeners() {}

  /**
   * Remove the map listeners of the given name
   */
  removeListeners(name: string = "default") {
    if (!(name in this.listeners)) {
      console.log(`Unknown listener '${name}'`);
      return;
    }

    let listeners = this.listeners[name];
    for (let i = 0; i < listeners.length; i++) {
      let listener = listeners[i];
      // console.log("Removing '" + name + "' listener on '" + listener.event + "' event for layer " + this.id);
      if (listener.on_map) {
        this.map.off(listener.event, listener.function);
      } else {
        this.map.off(listener.event, this.id, listener.function);
      }
    }
  }

  setFilter(filter) {
    this.map.setFilter(this.id, filter);
    this.filter = filter;
  }

  /**
   * Get list of exposed properties of the layer.
   * Transform them in the following format: { property: label }
   * and set the dataProperties attribute
   */
  readDataProperties() {
    let data_properties = this._dataProperties;
    if (typeof data_properties == "object" && !Array.isArray(data_properties)) {
      // when the properties have the expected format, just set the dataProperties attribute
      this.dataProperties = data_properties;
    } else if (typeof data_properties == "object" && Array.isArray(data_properties)) {
      // when an Array is provided, the properties are used as labels
      if (data_properties.length == 0) {
        console.log("List of tooltip properties is provided but is empty");
      }
      this.dataProperties = Object.fromEntries(data_properties.map(prop => [prop, prop]));
    } else if (typeof data_properties == "string") {
      if (data_properties in DATA_LAYERS_PROP_LABELS) {
        this.dataProperties = DATA_LAYERS_PROP_LABELS[data_properties];
      } else {
        console.log(`${data_properties} was not found in DATA_LAYERS_PROP_LABELS`);
      }
    } else if (data_properties === undefined) {
      // when no data properties are provided, read list of properties in first data feature at layer load
      if (!this.source) {
        this.dataProperties = {};
        return;
      }
      this.newListener(
        "sourcedata",
        e => {
          if (e.sourceId === this.source.id && e.isSourceLoaded && this.dataProperties === undefined) {
            let props = this.source.getFeatureProps();
            if (props.length > 0) {
              this.dataProperties = Object.fromEntries(props.map(prop => [prop, prop]));
              this.removeListeners("data_properties");
            }
          }
        },
        "data_properties",
        true
      );
    } else {
      // otherwise, set empty list
      this.dataProperties = {};
    }
  }

  readEditAttributes(editAttributes) {
    // fetch default edit attributes
    this.editAttributes = convert_edit_attributes_to_mapping(this.defaultEditAttributes(), editAttributes);

    // fill edit store if type change is enabled on editAttributes
    for (const key in this.editAttributes) {
      let edit_attribute = this.editAttributes[key];
      this.editStore[key] = {
        [edit_attribute.getType()]: edit_attribute
      };
    }

    // get the mapping defining the legend
    this.legend = null;
    for (let edit_key in this.editAttributes) {
      const mapping = this.editAttributes[edit_key];
      if (mapping.legend) {
        if (mapping.paint_type != "color") {
          console.log("Only color legends are supported for now");
        } else if (this.legend != undefined) {
          throw new Error("Several edit attributes are set as legend");
        } else {
          this.legend = mapping;
        }
      }
    }

    // init tooltip mapping attribute
    let tooltip_attribute = editAttributes?.tooltip || [];
    let tooltip_mapping;
    if (isPropsMappingInit(tooltip_attribute)) {
      tooltip_mapping = jsonToPropsMapping(tooltip_attribute);
    } else if (typeof tooltip_attribute == "string") {
      tooltip_mapping = new TooltipMapping({ mapping_options: { keys: [tooltip_attribute] } });
    } else if (Array.isArray(tooltip_attribute)) {
      tooltip_mapping = new TooltipMapping({ mapping_options: { keys: tooltip_attribute } });
    } else {
      throw new Error("A key or an array of keys is expected for the 'tooltip' editAttribute");
    }
    this.tooltipAttribute = tooltip_mapping;
  }

  /**
   *
   * @returns an object containing the default edit attributes for this class
   */
  abstract defaultEditAttributes(): any;

  // Layer update methods

  /**
   * Set a new data source for the layer.
   */
  abstract setLayerData(data: any);

  /**
   * Set new data for the layer and make relevant changes.
   * @param data
   */
  setData(data: any) {
    console.log("Set data for " + this.id);
    // set data attribute
    this.data = data;

    // if data properties are infered from data, reset properties list and trigger evaluation
    if (this._dataProperties === undefined) {
      this.dataProperties = undefined;
      this.readDataProperties();
    }

    // set layer data
    this.setLayerData(data);
  }

  isDataEmpty(): boolean {
    return !this.data;
  }

  updateTooltipAttribute(tooltip_mapping) {
    // set attribute with new tooltip mapping
    this.tooltipAttribute = tooltip_mapping;

    // update list of layers in popup
    if (tooltip_mapping.mapping_options.keys.length > 0) {
      store.commit("layers/ADD_LAYER_TO_POPUP", this.id);
    } else {
      store.commit("layers/REMOVE_LAYER_FROM_POPUP", this.id);
    }
  }

  /**
   * Update the editAttributes and the layer features they correspond to.
   *
   * @param {Object} new_attributes
   * @param {boolean} set_edit_attributes
   */
  updateEditAttributes(new_attributes, set_edit_attributes = true) {
    let new_props_mapping;
    for (const key in new_attributes) {
      // check that attribute exists
      if (!(key in this.editAttributes)) {
        console.log(`Trying to update non existing edit attribute '${key}'`);
        continue;
      }

      // get new props mapping
      new_props_mapping = new_attributes[key];
      if (!PropsMapping.isPropsMapping(new_props_mapping)) {
        throw new Error("Provided edit attribute is not a PropsMapping");
      }

      // update editAttributes
      if (set_edit_attributes) {
        this.editAttributes[key] = new_props_mapping;
        if (this.enableAttributeTypeChange) {
          this.editStore[key][new_props_mapping.getType()] = new_props_mapping;
        }
      }

      // update paint
      if (new_props_mapping.paint) {
        this.updatePaint(key, new_props_mapping);
      }
    }
  }

  /**
   * Update the layer paint according to the edit key and paint type.
   * @param _edit_key name of the edited property
   * @param _props_mapping props mapping describing the new paint
   */
  updatePaint(_edit_key: string, _props_mapping: any) {
    throw new Error("Method updatePaint is not implemented");
  }

  /**
   * Update the layer's color according to the new attributes.
   *
   * @param {Object} new_attributes
   */
  updateColor(new_val: any) {
    throw new Error("Method not implemented");
  }

  /**
   * Update the layer's size according to the new attributes.
   *
   * @param {Object} new_attributes
   */
  updateSize(new_val: any) {
    throw new Error("Method not implemented");
  }

  /**
   * Redraw the layer by removing it and adding it again.
   */
  redraw() {
    if (this.map == null) {
      return;
    }

    // remove the layer from map and add it back
    let layer_info = this.map.getLayer(this.id);
    if (layer_info !== undefined) {
      this.map.removeLayer(this.id);
      this.map.addLayer(this.layerObj, this.beforeId);
      this.updateVisibility();
    }
  }

  /**
   * Set the beforeId attributes, which designates the upper layer.
   * @param {String} beforeId
   */
  setBeforeId(beforeId) {
    this.beforeId = beforeId;
  }

  /**
   * Update the layer visibility according to the attribute isVisible.
   */
  updateVisibility(isVisible?: boolean) {
    // set visibility if provided
    if (isVisible != undefined) {
      this.isVisible = isVisible;
    }

    // get visibility string
    const visibility = this.getVisibility();
    if (!this.map) {
      return;
    }
    // update layout property
    let layer_obj = this.map.getLayer(this.id);
    if (typeof layer_obj !== "undefined") {
      this.map.setLayoutProperty(this.id, "visibility", visibility);
    }
  }

  /**
   * Get the string describing the layer visibility.
   */
  getVisibility() {
    if (this.isVisible) {
      return "visible";
    } else {
      return "none";
    }
  }

  // Mapbox layer management methods

  /**
   * Set the map attribute with the Mapbox map.
   * @param {*} map
   */
  setMap(map) {
    this.map = map;
    if (this.source != null) {
      this.source.setMap(map);
    }
  }

  /**
   * Add the layer to the map.
   */
  addLayer(before_id?) {
    if (before_id != undefined) {
      this.beforeId = before_id;
    }

    let layer_info = this.map.getLayer(this.id);
    if (layer_info == undefined) {
      this.map.addLayer(this.layerObj, this.beforeId);
      this.updateVisibility();
      this.updateEditAttributes(this.editAttributes, false);
      this.updateTooltipAttribute(this.tooltipAttribute);
    }
  }

  /**
   * Remove the layer from the map.
   */
  removeLayer() {
    this.map.removeLayer(this.id);
  }

  /**
   * Remove the layer source from the map
   */
  removeSource() {
    if (this.source != null) {
      this.map.removeSource(this.source.id);
    }
  }

  moveLayer() {
    this.map.moveLayer(this.id, this.beforeId);
  }

  isLoading() {
    return this.source && this.source.loading;
  }

  /**
   * Return the id to use as beforeId when adding a layer behind this one
   *
   * @returns
   */
  getLastLayerId() {
    return this.id;
  }

  /**
   * Test if the layer has been added to the map.
   *
   * @param {} map
   * @returns
   */
  existsOnMap(map) {
    let layer_obj = map.getLayer(this.id);
    return typeof layer_obj !== "undefined";
  }

  isCustomLayer() {
    return this.category == "custom";
  }

  isUserLayer() {
    return this.category == "custom" || this.category == "misc";
  }

  tooltipTextFromFeature(feature) {
    let tooltip_props = this.tooltipAttribute.mapping_options.keys;
    let output_text = "";
    // get displayed property
    for (let prop of tooltip_props) {
      let value = feature.properties[prop] || "";
      output_text = output_text + "<b>" + this.dataProperties[prop] + "</b>: ";
      if (typeof value == "string" && value.startsWith("{")) {
        output_text = output_text + "<br/>";
        let infos = JSON.parse(value);
        for (let info of Object.keys(infos)) {
          output_text = output_text + info + ": " + infos[info] + "<br/>";
        }
      } else if (typeof value == "number") {
        output_text = output_text + value.toLocaleString(i18n.locale, { maximumFractionDigits: 2 }) + "<br/>";
      } else {
        output_text = output_text + value + "<br/>";
      }
    }
    return output_text;
  }

  /**
   * Find layer features intersecting the point or bounding box
   * @param e
   * @param bbox_size
   * @returns
   */
  getFeatures(e, bbox_size = undefined) {
    let query;
    if (bbox_size) {
      query = [
        [e.point.x - bbox_size, e.point.y - bbox_size],
        [e.point.x + bbox_size, e.point.y + bbox_size]
      ];
    } else {
      query = e.point;
    }
    let features = this.map.queryRenderedFeatures(query, {
      layers: [this.id]
    });
    return features;
  }

  /**
   * Add a popup that appears when hovering the polygons
   * @param {function} text_function, returns the text to display in the popup
   */
  addPopup(text_function) {
    // create a new popup that tracks the pointer
    var popup = new mapboxgl.Popup({ closeButton: false, closeOnClick: false });
    popup.trackPointer();
    this.addHover(
      e => {
        var text = text_function(e);
        if (text) {
          popup.setHTML(text).addTo(this.map);
        } else {
          popup.remove();
        }
      },
      () => {
        popup.remove();
      }
    );
  }

  addHover(on_hover, on_leave) {}

  // Layer display methods

  /**
   *
   * @returns a string that renders the layer type
   */
  static itemType() {
    return "Undefined";
  }

  /**
   *
   * @returns layer display name
   */
  getName(with_extension = false) {
    let name;
    if (this.name === undefined) {
      name = i18n.t("kite_layers.layers." + this.id + ".name");
    } else {
      if (typeof this.name === "string") {
        name = this.name;
      } else {
        let layerName = this.name[i18n.locale];
        if (layerName === undefined) {
          name = this.id;
        } else {
          name = layerName;
        }
      }
    }
    if (with_extension) {
      return name + ".geojson";
    } else {
      return name;
    }
  }

  // map export legend

  addLegendToExport(map_export) {
    if (this.legend) {
      this.legend.addToMapExport(map_export, this);
    }
  }

  addExportLegendFigure(_map_export, _color) {
    throw new Error("addExportLegendFigure not implemented");
  }

  getLegendText() {
    return this.getName();
  }

  getMinZoom() {
    return this.map.getLayer(this.id).minzoom;
  }

  async evaluateSizeCoefficient(property: string) {
    let data = await this.source.getSpatialData();
    let values = data.features.map(f => f.properties[property]);
    // TODO manage strings
    values = values.filter(v => !!v);
    let coefficient: number;
    if (values.length == 0) {
      coefficient = 1;
    } else {
      let raw_coefficient = TARGET_LAYER_SIZE / Math.max(...values);
      if (raw_coefficient >= 1) {
        coefficient = +raw_coefficient.toFixed(1);
      } else {
        // truncate to first two non-zero decimals
        let first_decimal_index = Math.floor(Math.abs(Math.log10(raw_coefficient)));
        coefficient = +raw_coefficient.toFixed(first_decimal_index + 2);
      }
    }
    return coefficient;
  }

  signalLayerError(e) {
    // we only store the first error encountered to avoid spam
    if (!this.errorState) {
      console.log(e);
      this.errorState = e;
      let message = i18n.t("errors.mapbox.layer", {
        layer: store.getters["layers/getLayer"](e.sourceId).getName(),
        message: e.error.message
      });
      alert({ message, type: "error" });
    }
  }
}

export abstract class KiteMultipleLayer extends KiteLayer {
  subLayers: any[];
  class_name = "KiteMultipleLayer";
  constructor(payload) {
    super(payload);

    // list of sublayers
    this.subLayers = [];

    if (payload.layerClasses.length == 0) {
      throw new Error("At least one layer class must be provided to KiteMultipleLayer");
    }

    for (let i = 0; i < payload.layerClasses.length; i++) {
      // setup layer props
      let defaultProps = this.defaultLayerProps();
      if (Array.isArray(defaultProps)) {
        defaultProps = defaultProps[i];
      }
      let props = payload.layerProps;
      if (Array.isArray(props)) {
        props = props[i];
      }
      props = {
        ...defaultProps,
        ...props,
        source: this.id
      };

      // get the id of the upper layer
      let layerBefore = null;
      if (i == 0) {
        layerBefore = this.beforeId;
      } else {
        layerBefore = this.subLayers[i - 1].id;
      }

      // create the layer constructor parameters
      let layerParams = {
        id: this.id + "-" + i,
        data: payload.data,
        layerProps: props,
        editable: payload.editable,
        editAttributes: payload.editAttributes,
        startsVisible: payload.startsVisible,
        category: payload.category,
        beforeId: layerBefore,
        parentId: this.id
      };

      // create the sublayer
      let layerClass = payload.layerClasses[i];
      let layer = new layerClass(layerParams);
      this.subLayers.push(layer);
    }
  }

  addToCollection() {
    KiteLayer.prototype.addToCollection.call(this);
    for (let i = 0; i < this.subLayers.length; i++) {
      let layer = this.subLayers[i];
      layer.layerObj = layer.addToCollection();
    }
  }

  createLayer() {
    for (let i = 0; i < this.subLayers.length; i++) {
      let layer = this.subLayers[i];
      layer.layerObj = layer.createLayer();
    }

    return null;
  }

  // Layer update methods

  setLayerData(data: any) {
    this.subLayers[0].setLayerData(data);
  }

  updateEditAttributes(new_attributes, set_edit_attributes) {
    KiteLayer.prototype.updateEditAttributes.call(this, new_attributes, set_edit_attributes);
    for (let i = 0; i < this.subLayers.length; i++) {
      this.subLayers[i].updateEditAttributes(new_attributes, set_edit_attributes);
    }
  }

  updatePaint(_edit_key: string, _props_mapping: any): void {}

  setBeforeId(beforeId) {
    this.beforeId = beforeId;
    this.subLayers[0].setBeforeId(beforeId);
  }

  updateVisibility(isVisible) {
    if (isVisible != undefined) {
      this.isVisible = isVisible;
    }

    for (let i = 0; i < this.subLayers.length; i++) {
      this.subLayers[i].updateVisibility(this.isVisible);
    }
  }

  setMap(map) {
    KiteLayer.prototype.setMap.call(this, map);
    for (let i = 0; i < this.subLayers.length; i++) {
      this.subLayers[i].setMap(map);
    }
  }

  addLayer(before_id) {
    if (before_id != undefined) {
      this.setBeforeId(before_id);
    }

    for (let i = 0; i < this.subLayers.length; i++) {
      this.subLayers[i].addLayer();
    }
  }

  removeLayer() {
    for (let i = 0; i < this.subLayers.length; i++) {
      this.subLayers[i].removeLayer();
    }
  }

  removeSource() {
    this.subLayers[0].removeSource();
  }

  moveLayer() {
    for (let i = 0; i < this.subLayers.length; i++) {
      this.subLayers[i].moveLayer();
    }
  }

  getLastLayerId() {
    let last = this.subLayers[this.subLayers.length - 1].id;
    return last;
  }

  existsOnMap(map) {
    for (let i = 0; i < this.subLayers.length; i++) {
      if (!this.subLayers[i].existsOnMap(map)) {
        return false;
      }
    }
    return true;
  }

  static itemType() {
    return "Multiple";
  }

  getMinZoom() {
    let min_zoom_list = this.subLayers
      .map(layer => {
        return layer.getMinZoom();
      })
      .filter(zoom => zoom !== undefined);

    if (min_zoom_list.length == 0) {
      return undefined;
    } else {
      return Math.min(...min_zoom_list);
    }
  }
}

// Mapbox layer class
abstract class KiteMapboxLayer extends KiteLayer {
  class_name = "KiteMapBoxLayer";

  _color_prop = undefined;
  _opacity_prop = undefined;
  _size_prop = undefined;

  defaultEditAttributes(): any {
    return {
      color: {
        type: "constant",
        legend: true,
        paint_type: "color",
        mapping_options: {
          value: "#000000"
        }
      },
      size: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 3
        }
      },
      opacity: {
        type: "constant",
        paint_type: "opacity",
        mapping_options: {
          value: 1
        }
      }
    };
  }

  setLayerData(data: any) {
    // data is set via the source for the mapbox layers
    this.source.setData(data);
  }

  addLayer(beforeId) {
    // get the source object from the layer obj

    // add the source if it does not already exist
    this.source.addSource();
    KiteLayer.prototype.addLayer.call(this, beforeId);
  }

  updatePaint(_edit_key: string, props_mapping: any): void {
    let paint_type = props_mapping.paint_type;
    let paint_expression = props_mapping.toMapboxExpression();
    switch (paint_type) {
      case "color":
        this.setColorPaint(paint_expression);
        break;
      case "size":
        this.setSizePaint(paint_expression);
        break;
      case "opacity":
        this.setOpacityPaint(paint_expression);
        break;
    }
  }

  setColorPaint(val): void {
    this.setPaintProp(this._color_prop, val);
  }

  setOpacityPaint(val): void {
    this.setPaintProp(this._opacity_prop, val);
  }

  setSizePaint(val): void {
    this.setPaintProp(this._size_prop, val);
  }

  getOpacityPaint(): number {
    return this.map.getPaintProperty(this.id, this._opacity_prop);
  }

  /**
   * Set a paint property of the layer.
   *
   * @param {String} prop
   * @param {*} value
   */
  setPaintProp(prop, value) {
    this.map.setPaintProperty(this.id, prop, value);
  }
}

// Mapbox circle layer class
export class KiteCircleLayer extends KiteMapboxLayer {
  class_name = "KiteCircleLayer";

  _color_prop = "circle-color";
  _opacity_prop = "circle-opacity";
  _size_prop = "circle-radius";

  typeProp() {
    return "circle";
  }

  setSizePaint(val) {
    let radius = ["*", ["sqrt", val], 5];
    this.setPaintProp("circle-radius", radius);
  }

  static itemType() {
    return "Circle";
  }

  addHover(on_hover, on_leave) {
    this.newListener("mousemove", on_hover, "hover", true);
  }

  addExportLegendFigure(map_export, color) {
    let opacity = this.getOpacityPaint();
    map_export.addLegendCircle(color, opacity);
  }
}

// Mapbox line layer class
export class KiteLineLayer extends KiteMapboxLayer {
  class_name = "KiteLineLayer";

  _color_prop = "line-color";
  _opacity_prop = "line-opacity";
  _size_prop = "line-width";

  typeProp() {
    return "line";
  }

  defaultLayerProps() {
    return {
      layout: {
        "line-join": "round",
        "line-cap": "round"
      }
    };
  }

  static itemType() {
    return "Line";
  }

  addHover(on_hover, on_leave) {
    this.newListener("mousemove", on_hover, "hover", true);
  }

  addExportLegendFigure(map_export, color) {
    let opacity = this.getOpacityPaint();
    map_export.addLegendLine(color, opacity);
  }
}

export class KiteSymbolLayer extends KiteMapboxLayer {
  class_name = "KiteSymbolLayer";

  defaultEditAttributes() {
    return {};
  }

  constructor(payload) {
    super({
      ...payload,
      legend_export: false
    });
  }

  typeProp() {
    return "symbol";
  }

  static itemType() {
    return "Symbol";
  }

  addHover(on_hover, on_leave) {
    this.newListener("mousemove", on_hover, "hover", true);
  }
}

export class KiteFillLayer extends KiteMapboxLayer {
  class_name = "KiteFillLayer";

  _color_prop = "fill-color";
  _opacity_prop = "fill-opacity";

  defaultEditAttributes() {
    let base = KiteMapboxLayer.prototype.defaultEditAttributes.call(this);
    return {
      color: base.color,
      opacity: base.opacity
    };
  }

  typeProp() {
    return "fill";
  }

  setSizePaint(val: any): void {
    throw new Error("Cannot set size of Fill layer");
  }

  static itemType() {
    return "Fill";
  }

  addHover(on_hover, on_leave) {
    this.newListener("mousemove", on_hover, "hover");
    this.newListener("mouseleave", on_leave, "hover");
  }

  addExportLegendFigure(map_export, color) {
    let opacity = this.getOpacityPaint();
    map_export.addLegendRect(color, opacity);
  }
}

export class KiteMaskLayer extends KiteFillLayer {
  constructor(payload) {
    // force source type to GeojsonMask
    payload.sourceType = "geojson_mask";
    super(payload);
  }

  static itemType() {
    return "Mask";
  }

  defaultEditAttributes(): any {
    return {
      color: {
        type: "constant",
        legend: true,
        paint_type: "color",
        mapping_options: {
          value: "#FFFFFF"
        }
      },
      opacity: {
        type: "constant",
        paint_type: "opacity",
        mapping_options: {
          value: 0.8
        }
      }
    };
  }
}

export class KiteFillSelectLayer extends KiteFillLayer {
  class_name = "KiteFillSelectLayer";
  defaultLayerProps() {
    return {
      paint: {
        "fill-outline-color": "#000000"
      }
    };
  }

  setColorPaint(val: any): void {
    KiteFillLayer.prototype.setColorPaint.call(this, [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      val,
      "#FFFFFF"
    ]);
  }

  /**
   * Add selection and action when features are clicked
   * @param {*} on_select function called on feature selection
   * @param {*} on_unselect function called on feature unselection
   */
  addClickAction(on_select, on_unselect) {
    this.newListener("click", e => {
      if (e.features.length > 0) {
        // get the feature info
        let feature = e.features[0];
        let feat_id = feature.properties[this.source.sourceProps.promoteId];
        let is_selected = this.map.getFeatureState({
          source: this.id,
          id: feat_id,
          sourceLayer: this.source.sourceLayer
        }).selected;

        // if the feature was not selected, select and call on_select
        if (is_selected == undefined || !is_selected) {
          this.map.setFeatureState(
            {
              source: this.id,
              id: feat_id,
              sourceLayer: this.source.sourceLayer
            },
            { selected: true }
          );
          on_select(feature);

          // if the feature was selected, unselect and call on_unselect
        } else {
          this.map.setFeatureState(
            {
              source: this.id,
              id: feat_id,
              sourceLayer: this.source.sourceLayer
            },
            { selected: false }
          );
          on_unselect(feature);
        }
      }
    });
  }

  /**
   * Clear the feature state of the feature corresponding to the id
   * If no id is provided, all feature states are cleared
   * @param {*} feature_id
   */
  clearState(feature_id) {
    this.map.removeFeatureState({
      source: this.id,
      id: feature_id,
      sourceLayer: this.source.sourceLayer
    });
  }
}

export class KiteHeatmapLayer extends KiteMapboxLayer {
  class_name = "KiteHeatmapLayer";

  typeProp() {
    return "heatmap";
  }

  defaultEditAttributes() {
    return {
      radius: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 15,
          label: "Radius",
          constraints: {
            min: 1,
            max: 10
          }
        }
      },
      weight: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 0.5,
          label: "Weight",
          constraints: {
            min: 0,
            max: 1
          }
        }
      }
    };
  }

  updatePaint(edit_key: string, props_mapping: any): void {
    if (props_mapping.getType() != "constant") {
      throw new Error("KiteHeatmapLayer expects a ConstantMapping instance");
    }
    let paint_value = props_mapping.mapping_options.value;
    if (edit_key == "radius") {
      this.setPaintProp("heatmap-radius", ["interpolate", ["linear"], ["zoom"], 6, paint_value / 2, 12, paint_value]);
    } else if (edit_key == "weight") {
      this.setPaintProp("heatmap-weight", paint_value);
    }
  }

  static itemType() {
    return "Heatmap";
  }
}

export class KiteGeojsonLayer extends KiteMultipleLayer {
  class_name = "KiteGeojsonLayer";
  constructor(payload) {
    let layerClasses = [KiteCircleLayer, KiteLineLayer];
    super({
      ...payload,
      layerClasses
    });
  }

  defaultEditAttributes(): any {
    return {
      color: {
        type: "constant",
        paint_type: "color",
        mapping_options: {
          value: "#000000",
          label: "Color"
        }
      },
      size: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 3,
          label: "Size"
        }
      }
    };
  }

  typeProp() {
    return "geojson";
  }

  defaultLayerProps() {
    return [
      {
        filter: ["in", "$type", "Point"]
      },
      {}
    ];
  }

  static itemType() {
    return "Geojson";
  }

  addExportLegendFigure(map_export, color) {
    let opacity_mappings = Object.values(this.editAttributes).filter(
      <PropsMapping>(mapping) => mapping.paint_type == "opacity"
    );
    let opacity;
    if (opacity_mappings.length == 0) {
      opacity = 1;
    } else if (opacity_mappings.length == 1) {
      opacity = (<PropsMapping>opacity_mappings[0]).mapping_options.value;
    } else {
      throw new Error("Several opacity mappings found");
    }
    map_export.addLegendLine(color, color, opacity);
  }
}

class GtfsRoutesLayer extends KiteLineLayer {
  class_name = "GtfsRoutesLayer";

  constructor(payload) {
    let category = "gtfs";
    let dataProperties = {
      route_id: "ID",
      route_short_name: "Nom court",
      route_long_name: "Nom long",
      route_color: "Couleur"
    };
    super({
      category,
      dataProperties,
      legend_export: false,
      editable: true,
      ...payload
    });
  }

  defaultLayerProps() {
    return {
      layout: {
        "line-join": "round",
        "line-cap": "round"
      }
    };
  }

  defaultEditAttributes() {
    return {
      color: {
        type: "direct",
        paint_type: "color",
        mapping_options: {
          key: "route_color",
          default: null
        }
      },
      size: {
        // edition is broken by highlight interaction
        type: "category",
        paint_type: "size",
        mapping_options: {
          key: "route_type",
          values_map: {
            0: 4,
            1: 4,
            2: 4,
            3: 2
          },
          values_labels: {
            0: "basic_transport.route_types.0",
            1: "basic_transport.route_types.1",
            2: "basic_transport.route_types.2",
            3: "basic_transport.route_types.3"
          },
          default: 1.5
        }
      },
      opacity: {
        type: "constant",
        paint_type: "opacity",
        mapping_options: {
          value: 1,
          label: "Opacity"
        }
      }
    };
  }
}

class GtfsStopsLayer extends KiteCircleLayer {
  class_name = "GtfsStopsLayer";

  constructor(payload) {
    let category = "gtfs";
    let dataProperties = {
      stop_id: "ID",
      stop_name: "Nom"
    };
    super({
      category,
      dataProperties,
      legend_export: false,
      editable: true,
      ...payload
    });
  }

  defaultEditAttributes() {
    return {
      color: {
        type: "constant",
        paint_type: "color",
        mapping_options: {
          value: "#3d6482",
          label: "Color"
        }
      },
      size: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 1,
          label: "Size"
        }
      },
      opacity: {
        type: "constant",
        paint_type: "opacity",
        mapping_options: {
          value: 0.7,
          label: "Opacity"
        }
      }
    };
  }
}

class OffsetLineLayer extends KiteLineLayer {
  class_name: string = "OffsetLineLayer";
  _offset_prop: string = "line-offset";

  defaultLayerProps(): any {
    return {
      layout: {
        "line-join": "round"
      }
    };
  }

  static itemType() {
    return "OffsetLine";
  }

  setSizePaint(val): void {
    KiteLineLayer.prototype.setSizePaint.call(this, val);
    // offset is abs(line_size/2) + 0.5
    let offset_expression = ["+", ["abs", ["*", val, 0.5]], 0.5];
    this.setPaintProp(this._offset_prop, offset_expression);
  }
}

// Deckgl layer class
abstract class DeckglLayer extends KiteLayer {
  class_name = "DeckglLayer";
  enableAttributeTypeChange: boolean = false;
  createSource() {
    // deckgl layers don't use sources as mapbox layers do
    return null;
  }

  createLayer() {
    // Deckgl layers are created using the MapboxLayer class
    let layerObj = KiteLayer.prototype.createLayer.call(this);
    return new MapboxLayer(layerObj);
  }

  addData(layerProps) {
    layerProps.data = this.data;
  }

  setLayerData(data: any) {
    // set the data using the setProps method
    this.layerObj.setProps({
      data: data
    });
  }

  updateEditAttributes(new_attributes, set_edit_attributes) {
    KiteLayer.prototype.updateEditAttributes.call(this, new_attributes, set_edit_attributes);
    // redraw after updating the editAttributes ?
    this.redraw();
  }

  setProps(props) {
    this.layerObj.setProps(props);
  }
}

// Deckgl ScatterplotLayer class
export class KiteScatterPlot extends DeckglLayer {
  class_name = "KiteScatterPlot";
  enableAttributeTypeChange: boolean = false;

  defaultEditAttributes() {
    return {
      color: {
        type: "constant",
        paint_type: "color",
        mapping_options: {
          value: "#000000",
          label: "Color"
        }
      },
      size: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 3,
          label: "Size"
        }
      }
    };
  }

  typeProp() {
    return ScatterplotLayer;
  }

  updatePaint(edit_key: string, props_mapping: any): void {
    let paint_value = props_mapping.mapping_options.value;
    if (edit_key == "color") {
      this.setProps({
        getFillColor: hexToRGB(paint_value)
      });
    } else if (edit_key == "size") {
      this.setProps({
        getRadius: paint_value
      });
    }
  }

  static itemType() {
    return "Scatterplot";
  }

  addExportLegendFigure(map_export, color) {
    // missing opacity management
    map_export.addLegendCircle(color);
  }
}

// Deckgl ArcLayer class
export class KiteArcLayer extends DeckglLayer {
  class_name = "KiteArcLayer";
  enableAttributeTypeChange: boolean = false;
  typeProp() {
    return ArcLayer;
  }

  defaultEditAttributes() {
    return {
      source_color: {
        type: "constant",
        paint_type: "color",
        mapping_options: {
          value: "#FFFFFF",
          label: "Source color"
        }
      },
      target_color: {
        type: "constant",
        paint_type: "color",
        mapping_options: {
          value: "#000000",
          label: "Target color"
        }
      },
      size: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 10,
          label: "Size"
        }
      }
    };
  }

  updatePaint(edit_key: string, props_mapping: any): void {
    let paint_value = props_mapping.mapping_options.value;
    if (edit_key == "source_color") {
      this.setProps({
        getSourceColor: hexToRGB(paint_value)
      });
    } else if (edit_key == "target_color") {
      this.setProps({
        getTargetColor: hexToRGB(paint_value)
      });
    } else if (edit_key == "size") {
      this.setProps({
        getWidth: paint_value
      });
    }
  }

  static itemType() {
    return "Arc";
  }

  updateVisibility(isVisible) {
    KiteLayer.prototype.updateVisibility.call(this, isVisible);
  }
}

// Deckgl PathLayer class
export class KitePathLayer extends DeckglLayer {
  class_name = "KitePathLayer";
  enableAttributeTypeChange: boolean = false;
  typeProp() {
    return PathLayer;
  }

  defaultEditAttributes(): any {
    return {
      color: {
        type: "constant",
        paint_type: "color",
        mapping_options: {
          value: "#000000",
          label: "Color"
        }
      },
      size: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 3,
          label: "Size"
        }
      }
    };
  }

  updatePaint(edit_key: string, props_mapping: any): void {
    let paint_value = props_mapping.mapping_options.value;
    if (edit_key == "color") {
      this.setProps({
        getColor: hexToRGB(paint_value)
      });
    } else if (edit_key == "size") {
      this.setProps({
        getWidth: paint_value
      });
    }
  }

  addExportLegendFigure(map_export) {
    let color = this.editAttributes.color;
    // missing opacity management
    map_export.addLegendLine(color);
  }
}

// Specific Kite layers
export class OdArcLayer extends KiteArcLayer {
  class_name = "OdArcLayer";
  enableAttributeTypeChange: boolean = false;

  constructor({ id, data = null, startsVisible = false, beforeId, name, display_mode }) {
    super({
      id,
      data,
      sourceType: "geojson",
      sourceProps: {},
      layerProps: {},
      editable: false,
      editAttributes: undefined,
      dataProperties: false,
      enableTooltip: false,
      startsVisible,
      category: "starling_od",
      beforeId,
      parentId: undefined,
      name,
      dataSource: personalDataSourceName(),
      saved: false,
      display_mode
    });
  }

  defaultEditAttributes(): any {
    return {
      color: {
        editable: false,
        legend: true,
        paint: false,
        type: "category",
        paint_type: "color",
        mapping_options: {
          key: "None",
          values_map: {
            source: COLORS.secondary,
            target: COLORS.primary
          },
          values_labels: {
            source: i18n.t("kite_layers.layers.users-arclayer.origin"),
            target: i18n.t("kite_layers.layers.users-arclayer.destination")
          }
        }
      }
    };
  }

  defaultLayerProps() {
    let color_values = this.editAttributes.color.mapping_options.values_map;
    return {
      fp64: true,
      pickable: true,
      highlightColor: [97, 163, 188, 255],
      autoHighlight: true,
      getSourcePosition: d => [d.geometry.coordinates[0][0], d.geometry.coordinates[0][1]],
      getTargetPosition: d => [d.geometry.coordinates[1][0], d.geometry.coordinates[1][1]],
      getWidth: 10,
      getSourceColor: hexToRGB(color_values.source),
      getTargetColor: hexToRGB(color_values.target),
      onHover: info => {
        const el = document.getElementById("decktooltip");
        if (info.object && this.isVisible) {
          el.innerHTML = info.object.properties.agent_id;
          el.style.display = "block";
          el.style.left = info.x + "px";
          el.style.top = info.y + 60 + "px"; // gap for making tooltip closer to arc
        } else {
          el.style.display = "none";
        }
      }
    };
  }
}

export class KiteFlowMapLayer extends DeckglLayer {
  class_name = "KiteFlowMapLayer";
  enableAttributeTypeChange: boolean = false;
  max: number;
  constructor({
    id,
    data = null,
    layerProps = {},
    editable = false,
    editAttributes,
    startsVisible = false,
    category,
    beforeId,
    name
  }) {
    super({
      id,
      data,
      layerProps,
      editable,
      editAttributes,
      dataProperties: false,
      enableTooltip: false,
      startsVisible,
      category,
      beforeId,
      name
    });
    this.max = 999999999;
  }

  isDataEmpty() {
    if (this.data) {
      return this.data.flows?.length == 0 || this.data.locations?.length == 0;
    } else {
      return true;
    }
  }

  static itemType() {
    return "FlowMap";
  }

  typeProp() {
    return FlowMapLayer;
  }

  defaultEditAttributes() {
    return {
      base_color: {
        type: "constant",
        legend: true,
        paint_type: "color",
        mapping_options: {
          value: COLORS.primary,
          label: "Base color"
        }
      },
      highlight_color: {
        type: "constant",
        paint_type: "color",
        mapping_options: {
          value: COLORS.secondary,
          label: "Highlight color"
        }
      },
      opacity: {
        type: "constant",
        paint_type: "opacity",
        mapping_options: {
          value: 1,
          constraints: {
            min: 0,
            max: 1,
            step: 0.1
          }
        }
      },
      width: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 10,
          label: "Width",
          constraints: {
            min: 5,
            max: 14
          }
        }
      },
      radius: {
        type: "constant",
        paint_type: "size",
        mapping_options: {
          value: 15,
          label: "Radius",
          constraints: {
            min: 1,
            max: 20
          }
        }
      }
    };
  }

  updatePaint(edit_key: string, props_mapping: any): void {
    let paint_type = props_mapping.paint_type;
    let paint_value = props_mapping.mapping_options.value;
    if (paint_type == "color" || edit_key == "opacity") {
      this.setProps({
        colors: {
          flows: {
            scheme: [
              this.editAttributes.highlight_color.mapping_options.value,
              this.editAttributes.base_color.mapping_options.value
            ]
          },
          outlineColor: "#ffffff"
        }
      });
    } else if (edit_key == "width") {
      this.setProps({ maxFlowThickness: paint_value });
    } else if (edit_key == "radius") {
      this.setProps({ maxLocationCircleSize: paint_value });
    }
  }

  setLayerData(data: any) {
    // set the data using the setProps method
    this.max = this.maxCount(data.flows);
    this.layerObj.setProps({
      locations: data.locations,
      flows: data.flows
    });
    this.redraw();
  }

  defaultLayerProps() {
    return {
      fp64: true,
      data: null,
      locations: this.data.locations,
      flows: this.data.flows,
      getFlowMagnitude: f => f.count || 0,
      getFlowOriginId: f => f.origin,
      getFlowDestId: f => f.dest,
      getLocationId: l => l.id,
      getLocationCentroid: l => [l.lon, l.lat],
      getFlowColor: f => {
        let base_color = this.editAttributes.base_color.mapping_options.value;
        let highlight_color = this.editAttributes.highlight_color.mapping_options.value;
        let opacity = this.editAttributes.opacity.mapping_options.value;
        if (
          f.origin == this.layerObj.props.highlightedLocationId ||
          f.dest == this.layerObj.props.highlightedLocationId
        ) {
          // if a location is selected
          return highlight_color;
        } else if (f === this.layerObj.props.highlightedFlow) {
          // if flow is selected
          return highlight_color;
        } else {
          return base_color + this.exponentialNormalisation(f, this.max, opacity);
        }
      },
      showTotals: true, // if false, replace points with filled circles
      showOnlyTopFlows: 99999, // number of top flows
      highlightedLocationId: null, // highlight flows linked to this id
      highlightedFlow: null, // highlight a flow
      pickable: true,
      onHover: this.handleFlowMapHover(),
      onClick: this.handleFlowMapClick(),
      updateTriggers: {
        onHover: this.handleFlowMapHover(), // to avoid stale closure in the handler
        onClick: this.handleFlowMapClick()
      },
      showLocationAreas: false
      //minPickableFlowThickness: 0
    };
  }

  /**
   * Actions on click for FlowMap
   */
  handleFlowMapClick() {
    return info => {
      switch (info.type) {
        case "location":
          store.state.flows.currentFlowsView.setSelectedLocationId(info.object.id);
          this.layerObj.setProps({ highlightedLocationId: null });
      }
    };
  }

  /**
   * Actions on hover for FlowMap
   */
  handleFlowMapHover() {
    return info => {
      // set the tooltip
      this.setTooltipFlowMap(info.object, info.x, info.y, info.type);
      let highlightedLocationId = undefined;
      let highlightedFlow = undefined;
      if (info.picked) {
        switch (info.type) {
          case "location":
            if (store.getters["flows/getSelectedLocationId"] == null) {
              let id = null;
              if (info.object !== undefined) {
                id = info.object.id;
              }
              // highlight location and corresponding flows
              highlightedLocationId = id;
            }
            // if there is a selected location, unhighlight flow
            highlightedFlow = null;
            break;
          case "flow":
            highlightedFlow = info.object;
            highlightedLocationId = null;
            break;
        }
      } else {
        highlightedFlow = null;
        highlightedLocationId = null;
      }

      if (highlightedFlow !== undefined) {
        this.layerObj.setProps({ highlightedFlow });
      }
      if (highlightedLocationId !== undefined) {
        this.layerObj.setProps({ highlightedLocationId });
      }
      this.redraw();
    };
  }

  /**
   * Set tooltip for flowmap layer
   * See source => https://github.com/visgl/deck.gl/issues/3482
   */
  setTooltipFlowMap(object, x, y, type) {
    const el = document.getElementById("decktooltip");
    if (typeof object !== "undefined" && this.isVisible) {
      switch (type) {
        case "location":
          if ("id" in object) {
            // compute number of trips involving the location
            let trips = {
              ingoing: 0,
              outgoing: 0,
              internal: 0,
              total: 0
            };
            let flows = this.data.flows;
            for (let i = 0; i < flows.length; i++) {
              let origin = flows[i].origin;
              let dest = flows[i].dest;
              let count = flows[i].count;
              if (origin == dest && origin == object.id) {
                trips.internal = trips.internal + count;
              } else if (origin == object.id) {
                trips.outgoing = trips.outgoing + count;
              } else if (dest == object.id) {
                trips.ingoing = trips.ingoing + count;
              }
            }
            trips.total = trips.ingoing + trips.outgoing + trips.internal;
            // generate html tooltip content
            el.innerHTML =
              "<b>" +
              object.name +
              "</b><br/><br/>" +
              i18n.t("od.flowmap.in") +
              ": " +
              roundFlows(trips.ingoing, i18n.locale) +
              " (" +
              Math.round(100 * (trips.ingoing / trips.total)) +
              "%)<br/>" +
              i18n.t("od.flowmap.out") +
              ": " +
              roundFlows(trips.outgoing, i18n.locale) +
              " (" +
              Math.round(100 * (trips.outgoing / trips.total)) +
              "%)<br/>" +
              i18n.t("od.flowmap.internal") +
              ": " +
              roundFlows(trips.internal, i18n.locale) +
              " (" +
              Math.round(100 * (trips.internal / trips.total)) +
              "%)<br/>" +
              "<br/>" +
              "<i>" +
              i18n.t("od.flowmap.click") +
              "</i>";
            // set tooltip position
            el.style.display = "block";
            el.style.left = x + 30 + "px";
            el.style.top = y - 10 + "px";
          } else {
            el.style.display = "none";
          }
          break;
        case "flow":
          if ("origin" in object) {
            let label = "";
            try {
              if (object.label.length > 0) {
                label = "<br/><u><b>" + object.label + "</b></u>";
              }
            } catch (error) {
              label = "";
            }
            let flowData = this.data;
            // get origin & destination name
            let origin_name = flowData.locations.filter(d => d.id == object.origin)[0].name;
            let dest_name = flowData.locations.filter(d => d.id == object.dest)[0].name;
            // generate html tooltip content
            el.innerHTML =
              "<b>" +
              origin_name +
              " &rarr; " +
              dest_name +
              "</b>" +
              label +
              "<br/><br/>" +
              i18n.t("od.flowmap.trips") +
              ": " +
              "<b>" +
              roundFlows(object.count, i18n.locale) +
              "</b>";
            // set tooltip position
            el.style.display = "block";
            el.style.left = x + 30 + "px";
            el.style.top = y - 10 + "px";
          } else {
            el.style.display = "none";
          }
          break;
        default:
          el.style.display = "none";
      }
    } else {
      el.style.display = "none";
    }
  }

  exponentialNormalisation(flow, max, opacity) {
    let res = Math.floor(Math.exp((flow.count / max - 1) * (1 - opacity) * 5) * 255)
      .toString(16)
      .padStart(2, "0");
    return res;
  }

  maxCount(rawFlowData) {
    let max = 0;
    for (let i = 0; i < rawFlowData.length; i++) {
      if (rawFlowData[i]["count"] > max && rawFlowData[i]["origin"] !== rawFlowData[i]["dest"]) {
        max = rawFlowData[i]["count"];
      }
    }
    return max;
  }

  addLegendToExport(map_export) {
    map_export.addLegendHalfArrow(this.editAttributes.base_color.mapping_options.value);
    map_export.addLegendText(this.getName() + `            (Max: ${this.max.toFixed(1)})`);
  }
}

const DECKGL_TRACE = {
  layer_class: KiteScatterPlot,
  id: "",
  label: "",
  startsVisible: false,
  data: null,
  editAttributes: {
    size: 10
  },
  layerProps: {
    radiusMinPixels: 0.5,
    getPosition: d => [d.geometry.coordinates[0], d.geometry.coordinates[1], 0],
    getFillColor: d => DECK_PT_COLORS[d.properties.icon_type].color
  }
};

const KITE_LAYERS = [
  {
    layer_class: KiteFillLayer,
    id: "isochrone-public-transport",
    category: "gtfs",
    editAttributes: {
      color: {
        editable: false,
        legend: true,
        paint: true,
        type: "category",
        mapping_options: {
          key: "classe",
          values_map: {
            0: "#1a9850",
            1: "#91cf60",
            2: "#d9ef8b",
            3: "#fee08b",
            4: "#fc8d59",
            5: "#d73027"
          },
          values_labels: {
            0: "Moins de 10",
            1: "10 à 20",
            2: "20 à 30",
            3: "30 à 40",
            4: "40 à 50",
            5: "Plus de 50"
          }
        },
        unit: "minutes"
      },
      opacity: 0.7
    }
  },
  // layer used for displaying gtfs routes geometry
  {
    layer_class: GtfsRoutesLayer,
    id: "gtfs-lines"
  },
  {
    layer_class: GtfsStopsLayer,
    id: "gtfs-stops"
  },
  {
    layer_class: KiteLineLayer,
    id: "drawn-pt-line-buffer",
    category: "gtfs",
    editable: true,
    editAttributes: {
      color: "#000000",
      size: 1,
      opacity: 1
    },
    layerProps: {},
    legend_export: false
  },
  {
    layer_class: KiteLineLayer,
    id: "drawn-pt-line",
    category: "gtfs",
    editable: true,
    editAttributes: {
      color: "#000000",
      size: 3,
      opacity: 1
    },
    layerProps: {},
    legend_export: false
  },
  {
    layer_class: KiteHeatmapLayer,
    id: "users-heatmap",
    category: "starling_od",
    editable: true,
    legend_export: false
  },
  {
    layer_class: KiteScatterPlot,
    id: "origin-scatterplot",
    category: "starling_od",
    editable: true,
    editAttributes: {
      size: 5,
      color: "#85c287"
    },
    layerProps: {
      radiusUnits: "pixels",
      getPosition: d => [d.geometry.coordinates[0][0], d.geometry.coordinates[0][1], 0]
    },
    legend_export: false // because map_export does not work for now
  },
  {
    layer_class: KiteScatterPlot,
    id: "destination-scatterplot",
    category: "starling_od",
    editable: true,
    editAttributes: {
      size: 5,
      color: "#3d6482"
    },
    layerProps: {
      radiusUnits: "pixels",
      getPosition: d => [d.geometry.coordinates[1][0], d.geometry.coordinates[1][1], 0]
    },
    legend_export: false // because map_export does not work for now
  },
  {
    layer_class: OdArcLayer,
    id: "users-arclayer",
    legend_export: true
  },
  {
    layer_class: OdArcLayer,
    id: "users-arclayer-simulation",
    display_mode: "hidden",
    legend_export: false
  },
  {
    layer_class: KiteFlowMapLayer,
    id: "users-flowmap",
    data: { flows: [], locations: [] },
    category: "flowmap_od",
    editable: true
  },
  {
    layer_class: KiteLineLayer,
    id: "graph",
    category: "trace",
    editable: true,
    editAttributes: {
      color: "#000000",
      size: 1,
      opacity: 0.5
    },
    layerProps: {
      layout: {
        "line-join": "round",
        "line-cap": "round"
      }
    }
  },
  {
    layer_class: KiteLineLayer,
    id: "area",
    category: "trace",
    editable: true,
    editAttributes: {
      color: "#bf2877",
      size: 4,
      opacity: 1
    },
    layerProps: {
      layout: {
        "line-join": "round",
        "line-cap": "round"
      }
    }
  },
  {
    layer_class: KiteSymbolLayer,
    id: "staticpoint",
    category: "trace",
    layerProps: {
      layout: {
        // Control the icon image using data-driven styles
        "icon-image": {
          property: "icon_type",
          type: "identity"
        },
        "icon-size": ["interpolate", ["linear"], ["zoom"], 10, 0.2, 14, 1],
        "icon-allow-overlap": true,
        // Control the icon rotation with data driven styles
        // "icon-rotate": {
        //   property: "icon_rotate",
        //   type: "identity"
        // }
        "text-field": ["get", "show_attribute"],
        "text-anchor": "bottom",
        "text-offset": [0, 2],
        "text-justify": "center",
        "text-allow-overlap": true
      }
    }
  },
  {
    layer_class: KiteSymbolLayer,
    id: "stoppedmovingpoint",
    category: "trace",
    layerProps: {
      layout: {
        // Control the icon image using data-driven styles
        "icon-image": {
          property: "icon_type",
          type: "identity"
        },
        "icon-size": ["interpolate", ["linear"], ["zoom"], 10, 0.2, 14, 1],
        "icon-allow-overlap": true,
        // Control the icon rotation with data driven styles
        // "icon-rotate": {
        //   property: "icon_rotate",
        //   type: "identity"
        // }
        "text-field": ["get", "show_attribute"],
        "text-anchor": "bottom",
        "text-offset": [0, 2],
        "text-justify": "center",
        "text-allow-overlap": true
      }
    }
  },
  {
    layer_class: KiteSymbolLayer,
    id: "movingpoint",
    category: "trace",
    dynamic: true,
    layerProps: {
      layout: {
        // Control the icon image using data-driven styles
        "icon-image": {
          property: "icon_type",
          type: "identity"
        },
        "icon-size": ["interpolate", ["linear"], ["zoom"], 10, 0.2, 14, 1],
        "icon-allow-overlap": true,
        // Control the icon rotation with data driven styles
        // "icon-rotate": {
        //   property: "icon_rotate",
        //   type: "identity"
        // }
        "text-field": ["get", "show_attribute"],
        "text-anchor": "bottom",
        "text-offset": [0, 2],
        "text-justify": "center",
        "text-allow-overlap": true
      }
    }
  }
];

const TARGET_LAYER_SIZE = 10;

const SOURCE_TYPES = {
  geojson: GeojsonSource,
  geojson_mask: GeojsonMaskSource,
  vector: VectorTilesSource
};

const CATEGORY_COLORS = {
  starling_od: "#50B389",
  flowmap_od: "#137CBD",
  trace: "#5C4AD2",
  gtfs: "#C38F25",
  custom: "#601AAB",
  misc: "#A23232",
  other: "#4D4D4D"
};

function getNameColor(layer) {
  let layerCategory = layer.category;
  if (layerCategory in CATEGORY_COLORS) {
    return CATEGORY_COLORS[layerCategory];
  } else {
    return CATEGORY_COLORS.other;
  }
}

const LAYER_CLASSES = {
  KiteLineLayer,
  KiteCircleLayer,
  KiteSymbolLayer,
  KiteFillLayer,
  KiteMaskLayer,
  KiteFillSelectLayer,
  KiteHeatmapLayer,
  KiteGeojsonLayer,
  GtfsRoutesLayer,
  GtfsStopsLayer,
  OffsetLineLayer,
  KiteScatterPlot,
  KiteArcLayer,
  KitePathLayer,
  OdArcLayer,
  KiteFlowMapLayer
};

function convert_edit_attributes_to_mapping(default_edit_attributes, provided_edit_attributes) {
  let edit_keys = Object.keys(default_edit_attributes);
  let editAttributes = provided_edit_attributes || default_edit_attributes;
  // create an editAttribute object { edit_key: PropsMapping } from the default values and the given ones
  let final_edit_attributes = {};
  if (editAttributes) {
    let edit_attribute_value;

    for (const edit_key in editAttributes) {
      // special case for 'tooltip' key, managed in a different way
      if (edit_key == "tooltip") {
        continue;
      }

      // if the given edit attribute key is not authorized, log and ignore
      if (!edit_keys.includes(edit_key)) {
        console.log(
          `Provided edit attributes contain unsupported key '${edit_key}'. Authorized keys are [${edit_keys}].`
        );
        continue;
      }
      // get the edit attribute value
      edit_attribute_value = editAttributes[edit_key];

      if (isPropsMappingInit(edit_attribute_value)) {
        // directly create the PropsMapping object from the provided value if possible
        if (
          edit_attribute_value.paint_type &&
          edit_attribute_value.paint_type != default_edit_attributes[edit_key].paint_type
        ) {
          throw new Error("Provided paint type is different from default edit attribute paint type");
        }
        edit_attribute_value.paint_type = default_edit_attributes[edit_key].paint_type;
        final_edit_attributes[edit_key] = jsonToPropsMapping(edit_attribute_value);
      } else {
        // otherwise, create a PropsMapping from the default edit attribute
        // and set the provided value as mapping options
        try {
          let default_edit_attribute = default_edit_attributes[edit_key];
          default_edit_attribute = jsonToPropsMapping(default_edit_attribute);
          let mapping_options =
            default_edit_attribute.getType() == "constant" ? { value: edit_attribute_value } : edit_attribute_value;
          default_edit_attribute.setMappingValues(mapping_options);
          final_edit_attributes[edit_key] = default_edit_attribute;
        } catch (e) {
          console.log(e);
          throw new Error(`Error while trying to create PropsMapping from default edit attribute '${edit_key}'.}.
                 Make sure that this key exists in the default attributes and that the value can be used to create a PropsMapping instance.`);
        }
      }
    }
  }
  return final_edit_attributes;
}

/**
 * Repair #deprecatedEditAttributes
 * @param layer_class
 * @param edit_attributes
 * @returns
 */
function repair_edit_attributes(layer_class, edit_attributes) {
  if (!edit_attributes) {
    return undefined;
  }
  // manage map views with former editAttribute format
  let repaired_edit_attributes = {};
  let edit_attribute;
  for (const key in edit_attributes) {
    edit_attribute = edit_attributes[key];
    if (
      layer_class in FORMER_EDIT_ATTRIBUTES &&
      key in FORMER_EDIT_ATTRIBUTES[layer_class] &&
      Array.isArray(edit_attribute)
    ) {
      for (let i = 0; i < edit_attribute.length; i++) {
        repaired_edit_attributes[FORMER_EDIT_ATTRIBUTES[layer_class][key][i]] = edit_attribute[i];
      }
    } else if (key == "color" && typeof edit_attribute == "string" && edit_attribute.length == 9) {
      let hexa = hexaToHex(edit_attribute);
      repaired_edit_attributes[key] = hexa[0];
      repaired_edit_attributes["opacity"] = +hexa[1].toFixed(2);
    } else {
      repaired_edit_attributes[key] = edit_attribute;
    }
  }

  return repaired_edit_attributes;
}

const FORMER_EDIT_ATTRIBUTES = {
  KiteHeatmapLayer: {
    size: ["radius", "weight"]
  },
  KiteFlowMapLayer: {
    color: ["base_color", "highlight_color"],
    size: ["opacity", "width", "radius"]
  }
};

/**
 * Add 3D control for map
 *
 * @param {*} map
 * @param {*} minpitchzoom
 */
function add_3D_control(map, minpitchzoom = null) {
  map.addControl(new PitchToggle({ minpitchzoom: minpitchzoom }));
}

function add_draw_control(map) {
  if (store.state.layers.draw == null) {
    // problem, cannot import CommonSelectors from mapbox-gl-draw
    // disabling draw_point mode for now
    /* // create a control for drawing many points at a time
    var LotsOfPointsMode: any = {};
    LotsOfPointsMode.onSetup = function (opts) {
      return { count: opts.count ?? 0 };
    };

    // Whenever a user clicks on the map, Draw will call `onClick`
    LotsOfPointsMode.onClick = function (state, e) {
      if (CommonSelectors.isFeature(e)) return this.clickOnPoint(state, e);
      // `this.newFeature` takes geojson and makes a DrawFeature
      var point = this.newFeature({
        type: "Feature",
        properties: {
          count: state.count
        },
        geometry: {
          type: "Point",
          coordinates: [e.lngLat.lng, e.lngLat.lat]
        }
      });
      this.addFeature(point); // puts the point on the map
    };

    // Whenever a user clicks on a key while focused on the map, it will be sent here
    LotsOfPointsMode.onKeyUp = function (_, e) {
      if (e.keyCode === 27) return this.changeMode("simple_select");
    };

    // when a user clicks on an existing point, end the drawing state
    LotsOfPointsMode.clickOnPoint = function () {
      return this.changeMode("simple_select");
    };

    LotsOfPointsMode.toDisplayFeatures = function (_, geojson, display) {
      display(geojson);
    }; */

    // create the MapboxDraw control and add it to the map
    let draw = new MapboxDraw({
      displayControlsDefault: false,
      styles: [
        // documentation for styling https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#styling-draw
        // drawn lines
        {
          id: "gl-draw-line",
          type: "line",
          filter: ["all", ["!=", "mode", "static"]],
          layout: {
            "line-cap": "round",
            "line-join": "round"
          },
          paint: {
            "line-color": "#3d6482",
            "line-dasharray": [0.3, 2],
            "line-width": 6,
            "line-opacity": 0.7
          }
        },
        // active vertex points
        {
          id: "gl-draw-polygon-and-line-vertex-active",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
            ["==", "active", "true"]
          ],
          paint: {
            "circle-radius": 10,
            "circle-color": "#85c287"
          }
        },
        // disactive vertex points
        {
          id: "gl-draw-polygon-and-line-vertex-disactive",
          type: "circle",
          filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
            ["==", "active", "false"]
          ],
          paint: {
            "circle-radius": 10,
            "circle-color": "#3d6482"
          }
        },
        // midpoints
        {
          id: "gl-draw-polygon-and-line-midpoint-active",
          type: "circle",
          filter: ["all", ["==", "meta", "midpoint"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
          paint: {
            "circle-radius": 7,
            "circle-color": "#3d6482"
          }
        }
      ]
      // Adds the LotsOfPointsMode to the built-in set of modes

      /* modes: Object.assign(MapboxDraw.modes, {
        draw_point: LotsOfPointsMode
      }) */
    });
    map.addControl(draw);
    store.commit("layers/SET_MAP_DRAW", draw);
  }
}

export {
  setup_kite_layers,
  create_kite_layer,
  create_kite_layer_from_db,
  addCustomLayer,
  change_base_layer,
  add_background_layers,
  DECKGL_TRACE,
  CATEGORY_COLORS,
  getNameColor,
  LAYER_CLASSES,
  KITE_LAYERS,
  add_3D_control,
  add_draw_control,
  repair_edit_attributes,
  convert_edit_attributes_to_mapping
};
