<template>
  <v-edit-dialog @open="local_text_modification = text" @save="saveModification">
    <slot>
      {{ displayedText }}
    </slot>
    <template v-slot:input>
      <v-text-field
        v-model="local_text_modification"
        :rules="finalRules"
        :counter="finalCounter"
        :hint="$t('flows.manager.table.edit_confirm')"
        single-line
        class="pa-3"
      ></v-text-field>
    </template>
  </v-edit-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import { requiredRule, nameLengthRule, name_max_size, nameRegexRule } from "@/validation";

export default Vue.component("kite-edit-text", {
  props: {
    text: {
      type: String
    },
    rules: {
      type: Array
    },
    counter: {
      type: Number
    },
    displayText: {
      type: String
    },
    kiteName: {
      type: Boolean
    }
  },

  data() {
    return {
      local_text_modification: ""
    };
  },

  computed: {
    displayedText() {
      if (!this.displayText) {
        return this.text;
      }
      if (typeof this.displayText == "string") {
        return this.displayText;
      } else if (typeof this.displayText == "function") {
        return this.displayText(this.text);
      }
      return "undefined";
    },
    finalRules() {
      let final_rules = this.rules || [];

      // if text is a Kite name, add view name rules
      if (this.kiteName) {
        final_rules = [requiredRule, nameLengthRule, nameRegexRule, ...final_rules];
      }
      return final_rules;
    },
    finalCounter() {
      if (this.counter) {
        return this.counter;
      } else if (this.kiteName) {
        return name_max_size;
      } else {
        return undefined;
      }
    }
  },

  methods: {
    saveModification() {
      for (let rule of this.rules) {
        if (typeof rule(this.local_text_modification) == "string" || !rule(this.local_text_modification)) {
          return;
        }
      }
      this.$emit("save", this.local_text_modification);
    }
  }
});
</script>
